import React, { useRef, useEffect } from "react";
import moment from "moment";
import ReminderToggle from "./ReminderToggle";
import MonthReminder from "./MonthReminder";
import scrollToComponent from "react-scroll-to-component";
const Month = (props) => {
  const { scrollToggle,setscrollToggle,todayScrollToggle,settodayScrollToggle,monthReminder, handleMarkAsDone } = props;

  const monthRef = useRef();

  useEffect(() => {
    let date = new Date();
    const currentMonth = moment(date).format("MMM YYYY");
    if (monthReminder.monthYear === currentMonth&&scrollToggle) {
      monthRef.current.scrollIntoView(false);
      setscrollToggle(false)
    }
  }, [monthReminder]);

  return (
    <div ref={monthRef}>
      <div className="reminder_history_month">{monthReminder.monthYear}</div>
       
      {monthReminder.reminder.length > 0 ? (
        monthReminder.reminder.map((reminder, index) => {
          return(
          <MonthReminder 
             index={index}
             todayScrollToggle={todayScrollToggle}
             settodayScrollToggle={settodayScrollToggle}
             reminder={reminder}
             handleMarkAsDone={handleMarkAsDone}
             monthReminder={monthReminder}
          />
          )
        })
      ) : (
        <p className="d-flex justify-content-center">No Reminder Found</p>
      )} 
    </div>
  );
};


export default Month;

import React from "react";
import logo from "../assets/images/NewLogo.png";
const ThankYoupage = () => {
  return (
    <div className="Thankyou">
      <a href={"https://tootle.ai"} target="_blank" rel="noreferrer">
        <h4>
          <img src={logo} width="180" />
        </h4>
      </a>
      <div className="box">
        <h4>ThankYou for your feedback</h4>
      </div>
    </div>
  );
};

export default ThankYoupage;

import React from "react";
import {
  Button,
  Label,
  FormGroup,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from "reactstrap";
const DeleteSectionModal = (props) => {
  const {
    showDeleteSectionModal,
    uId,
    deletedSectionId,
    handledeleteSection,
    deleteLoader,
    deletedSectionName,
    closeDeleteSectionModal,
  } = props;
  const onSubmit = () => {
    handledeleteSection(deletedSectionId, uId);
  };
  return (
    <div>
      <Modal isOpen={showDeleteSectionModal}>
        <ModalHeader toggle={closeDeleteSectionModal}>
          <h5 className="modal-title f-w-600" id="exampleModalLabel2">
            Confirmation
          </h5>
        </ModalHeader>
        <ModalBody>
          <FormGroup className="form-group mb-3 row">
            <Label className="col-xl-12 col-sm-12 mb-0">
              Do you want to delete Section ?
            </Label>
          </FormGroup>
        </ModalBody>
        <ModalFooter>
          <Button
            type="submit"
            className="custom-btn-fill"
            size="sm"
            onClick={onSubmit}
            disabled={deleteLoader}
          >
            {deleteLoader ? "Deleting..." : "Delete"}
          </Button>
          <Button
            onClick={closeDeleteSectionModal}
            className="custom-btn"
            size="sm"
          >
            Close
          </Button>
        </ModalFooter>
      </Modal>
    </div>
  );
};
export default DeleteSectionModal;

import React, { useState } from "react";

import logo from "../assets/images/NewLogo.png";
import LogoutConfirm from "../auth/LogoutConfirm";
import { authentication } from "../firebase/firebase";
import { useNavigate } from "react-router-dom";
import { hidehistoryDetailOnMobile } from "../redux";
import { connect } from "react-redux";
import { deviceType } from "../comman/Common";
import { useParams } from "react-router-dom";
import { Link } from "react-router-dom";
import {
  Button,
  ButtonDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Spinner,
} from "reactstrap";
const DetailHeader = (props) => {
  const { fetchUpdatedHistory } = props;

  const navigate = useNavigate();

  let params = useParams();

  const memoryId = params.memoryId;

  /* const "dropdownOpen" open dropdown of upload file */
  const [dropdownToggle, setdropdownToggle] = useState(false);

  /* const "showLogoutConfirm" show logout popup */
  const [showLogoutConfirm, setshowLogoutConfirm] = useState(false);

  /**
   * This function show memory list
   */
  const showHistoryList = () => {
    const deviceOfType = deviceType();
    if (deviceOfType === "mobile" && fetchUpdatedHistory) {
      fetchUpdatedHistory();
    }
  };

  /**
   * This function  open Logout confirmation
   */
  const openLogOutConfirm = () => {
    setshowLogoutConfirm(true);
  };

  /**
   *This function Logout the user
   */
  const Logout = () => {
    localStorage.removeItem("userId");
    localStorage.clear();
    authentication.signOut();
    navigate("/");
  };

  return (
    <div>
      <div
        className="d-flex flex-row align-items-center history-header"
        style={{ boxShadow: "0 2px 5px #e5e5e5" }}
      >
        <div style={{ flex: 1 }}>
          <span onClick={showHistoryList}>
            <img src={logo} alt="image" width="130" />
          </span>
        </div>
        <div>
          {deviceType() === "desktop" ? (
            <span onClick={() => navigate("/memory/reminders")}>
              <i
                className="far fa-bell"
                style={{ margin: "0 10px", fontSize: "20px" }}
              ></i>
            </span>
          ) : (
            <div>
              {memoryId === undefined && (
                <span onClick={() => navigate("/memory/reminders")}>
                  <i
                    className="far fa-bell"
                    style={{ margin: "0 10px", fontSize: "20px" }}
                  ></i>
                </span>
              )}
            </div>
          )}
        </div>
        <div style={{ paddingLeft: "5px" }}>
          <ButtonDropdown
            isOpen={dropdownToggle}
            toggle={() => setdropdownToggle(!dropdownToggle)}
            size="sm"
          >
            <DropdownToggle caret>
              <i className="fa fa-ellipsis-v" aria-hidden="true"></i>
            </DropdownToggle>

            <DropdownMenu>
              <Link
                style={{ textDecoration: "none", color: "black" }}
                to="/termsAndCondition"
              >
                <DropdownItem> Terms of Service </DropdownItem>
              </Link>

              <DropdownItem divider />
              <Link
                style={{ textDecoration: "none", color: "black" }}
                to="/privacyPolicy"
              >
                <DropdownItem> Privacy Policy </DropdownItem>
              </Link>

              <DropdownItem divider />
              <Link
                style={{ textDecoration: "none", color: "black" }}
                to="/support"
              >
                <DropdownItem> Support </DropdownItem>
              </Link>

              <DropdownItem divider />
              <DropdownItem onClick={openLogOutConfirm}>Logout</DropdownItem>
            </DropdownMenu>
          </ButtonDropdown>
        </div>
        {/* <Button onClick={openLogOutConfirm} className="custom-btn" size="sm">
          <i className="fa fa-ellipsis-v" aria-hidden="true"></i>
        </Button> */}
      </div>

      {showLogoutConfirm && (
        <LogoutConfirm
          showLogoutConfirm={showLogoutConfirm}
          closeLogoutConfirm={() => setshowLogoutConfirm(false)}
          Logout={Logout}
        />
      )}
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    showhistoryDetailpage: state.history?.showDetailpage,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    hidehistoryDetailOnMobile: () => dispatch(hidehistoryDetailOnMobile()),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(DetailHeader);

import React, { useState, useEffect } from "react";
import DatePicker from "react-datepicker";
import Select from "react-select";
import moment from "moment";
import {
  setcustomRepeatDuration,
  setrepeatOccurence,
  setcustomReminderUnit,
  setcustomReminderEndDateType
} from "../redux";
import { connect } from "react-redux";
const CustomReminder = (props) => {
  const { setshowCustomReminder, handleCustomReminder } = props;
  
  /* const "repeatCount" store repeat count */
  const [repeatCount, setrepeatCount] = useState("1");

  /* const "copyrepeatCount" store repeat count copy */
  const [copyrepeatCount, setcopyrepeatCount] = useState("1");

  /* const "selectedTimePeriod" store time period like day,week,month*/
  const [selectedTimePeriod, setselectedTimePeriod] = useState("");

  /* const "occurrencesCount" store occurrences count */
  const [occurrencesCount, setoccurrencesCount] = useState("2");

  /* const "copyOccurrencesCount" store occurrences count copy*/
  const [copyOccurrencesCount, setcopyOccurrencesCount] = useState("2");

  /* const "selectedEndDateOption" End date option */
  const [selectedEndDateOption, setselectedEndDateOption] = useState("Never");

  useEffect(() => {
    setselectedTimePeriod({
      value: "daily",
      label: "Day",
    });
    setoccurrencesCount(props.repeatOccurence?props.repeatOccurence:"2")
    setcopyOccurrencesCount(props.repeatOccurence?props.repeatOccurence:"2")
    setrepeatCount(props.customRepeatDuration?props.customRepeatDuration:"1")
    setcopyrepeatCount(props.customRepeatDuration?props.customRepeatDuration:"1")
    setselectedEndDateOption(props.customReminderEndDateType?props.customReminderEndDateType:"Never")
    if(props.customReminderUnit==="day"){
      setselectedTimePeriod({
        value: "daily",
        label: "Day",
      });
    }
    if(props.customReminderUnit==="week"){
      setselectedTimePeriod({
        value: "weekly",
        label: "Week",
      });
    }
    if(props.customReminderUnit==="month"){
      setselectedTimePeriod({
        value: "monthly",
        label: "Month",
      });
    }
    if(props.customReminderUnit==="year"){
      setselectedTimePeriod({
        value: "yearly",
        label: "Year",
      });
    }
  }, []);

  const defaultTimePeriods = [
    {
      value: "daily",
      label: "Day",
    },
    {
      value: "weekly",
      label: "Week",
    },
    {
      value: "monthly",
      label: "Month",
    },
    {
      value: "yearly",
      label: "Year",
    },
  ];

  /**
   * This function handle repeat count
   * @param {object} event
   */
  const handleRepeatCount = (event) => {
    const count = event.target.value;
    if (count === "" || count.charAt(0) === "0") {
      setcopyrepeatCount(repeatCount);
      setrepeatCount(count);
    } else {
      setcopyrepeatCount(count);
      setrepeatCount(count);
    }
  };

  /**
   * This function handle time period
   * @param {object} selectedOPtion
   */
  const handleTimePeriod = (selectedOPtion) => {
    setselectedTimePeriod(selectedOPtion);
  };

  /**
   * This funciton handle selection of End date
   * @param {object} endOption
   */
  const selectCustomReminderEndDate = (endOption) => {
    setselectedEndDateOption(endOption);
  };

  /**
   * This function handle Occurrences count
   * @param {object} event
   */
  const handleOccurrencesCount = (event) => {
    const count = event.target.value;
    if (count === "" || count.charAt(0) === "0") {
      setcopyOccurrencesCount(occurrencesCount);
      setoccurrencesCount(count);
    } else {
      setcopyOccurrencesCount(count);
      setoccurrencesCount(count);
    }
  };

  const showCustomReminderStatus = () => {
    if (copyrepeatCount === "1") {
      let status = `Repeats ${selectedTimePeriod.value}`;
      if (selectedEndDateOption === "Occurrences") {
        status = status + `. occur ${copyOccurrencesCount} times`;
      }
      return status;
    } else {
      let status = `Repeats every ${copyrepeatCount} ${selectedTimePeriod.label?.toLowerCase()}s `;
      if (selectedEndDateOption === "Occurrences") {
        status = status + `.  occur ${copyOccurrencesCount} times`;
      }
       return status;
    }
  };

  const setCustomReminder = () => {
    props.setcustomRepeatDuration(copyrepeatCount);
    props.setcustomReminderUnit(selectedTimePeriod.label?.toLowerCase());
    props.setcustomReminderEndDateType(selectedEndDateOption)
    props.setrepeatOccurence(copyOccurrencesCount);
    if (selectedEndDateOption === "Never") {
      handleCustomReminder(
        copyrepeatCount,
        selectedTimePeriod,
        selectedEndDateOption
      );
    }
    if (selectedEndDateOption === "Occurrences") {
      handleCustomReminder(
        copyrepeatCount,
        selectedTimePeriod,
        selectedEndDateOption,
        copyOccurrencesCount
      );
    }
    setshowCustomReminder();
  };
  
  const customStyles = {
    control: (base) => ({
      ...base,
      border: 0,
      height: 35,
      minHeight: 35,

      // This line disable the blue border
      boxShadow: "none",
    }),
  };

  return (
    <div>
      <div className="d-flex align-items-center">
        <div onClick={setCustomReminder}>
          <i className="fas fa-arrow-left"></i>
        </div>
        <div className="my-2 time_font_size_16" style={{ paddingLeft: "10px" }}>
          Reminder
        </div>
      </div>
      <div className="custom_repeat_reminder_starts_container">
        <div style={{ width: "30%" }}>Every</div>
        <div>
          <input
            type="number"
            value={repeatCount}
            onChange={(event) => handleRepeatCount(event)}
            className="input_type_number"
          />
        </div>
        <div style={{ borderBottom: "1px solid gray", width: "40%" }}>
          <Select
            value={selectedTimePeriod}
            onChange={handleTimePeriod}
            options={defaultTimePeriods}
            styles={customStyles}
          />
        </div>
      </div>

      <div className="custom_repeat_reminder_ends_container">
        <div className="custom_repeat_reminder_ends_right_container">Ends</div>
        <div className="custom_repeat_reminder_ends_left_container">
          <div
            className="item"
            onClick={() => selectCustomReminderEndDate("Never")}
          >
            <input
              type="radio"
              value="Never"
              checked={selectedEndDateOption === "Never"}
              onChange={() => selectCustomReminderEndDate("Never")}
            />
            <span>Never</span>
          </div>
          <div
            className="item"
            onClick={() => selectCustomReminderEndDate("Occurrences")}
          >
            <div>
              <input
                type="radio"
                value="Occurrences"
                checked={selectedEndDateOption === "Occurrences"}
                onChange={() => selectCustomReminderEndDate("Occurrences")}
              />
            </div>
            <div>
              After
              <input
                type="number"
                name="number"
                value={occurrencesCount}
                onChange={(event) => handleOccurrencesCount(event)}
                className="input_type_number"
              />
              occurrences
            </div>
          </div>
        </div>
      </div>
      <div className="reminder_status">{showCustomReminderStatus()}</div>

      <div className="d-flex flex-row-reverse" style={{ marginTop: "10px" }}>
        <button className="save_time_button" onClick={setCustomReminder}>
          Done
        </button>
      </div>
    </div>
  );
};
const mapStateToProps = (state) => {
  return {
    customRepeatDuration: state.history?.customRepeatDuration,
    repeatOccurence: state.history?.repeatOccurence,
    customReminderUnit: state.history?.customReminderUnit,
    customReminderEndDateType:state.history?.customReminderEndDateType
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    setcustomRepeatDuration: (customRepeatDuration) => dispatch(setcustomRepeatDuration(customRepeatDuration)),
    setrepeatOccurence: (repeatOccurence) => dispatch(setrepeatOccurence(repeatOccurence)),
    setcustomReminderUnit: (customReminderUnit) =>
      dispatch(setcustomReminderUnit(customReminderUnit)),
    setcustomReminderEndDateType:(customReminderEndDateType)=>dispatch(setcustomReminderEndDateType(customReminderEndDateType))
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(CustomReminder);


import React from "react";
import vedioImage from "../assets/images/video2.png";
import pdfImage from "../assets/images/pdfPreview.png";
import Nopreview from "../assets/images/Nopreview.png";
const CreateAllFileRow = (props) => {
  const type = props.mimeType.split("/");
  if (
    props.mimeType === "image/jpeg" ||
    props.mimeType === "image/png" ||
    props.mimeType === "image/jpg"
  ) {
    return <img src={props.path} alt="img" />;
  }
  if (type[0] === "video") {
    return <img src={vedioImage} alt="img" />;
  }
  if (props.mimeType === "application/pdf") {
    return <img src={pdfImage} alt="img" width={20} />;
  }
  return <img src={Nopreview} alt="img" />;
};

export default React.memo(CreateAllFileRow);

import React from "react";
import { Link, Outlet } from "react-router-dom";
import SideBarData from "./SideBarData";
import { Col } from "reactstrap";
import { connect } from "react-redux";
import { setMenuToggle } from "../../redux";
const AdminSideBar = (props) => {
  return (
    <div className="admin-main-container">
      <div className="d-flex">
        {props.menuToggle ? (
          <>
            <Col lg="3" className="admin-sidebar">
              {SideBarData.map((data) => {
                return (
                  <Link to={`${data.path}`}>
                    <div className="sidebar-item">
                      <div>{data.icon}</div>
                      {data.title}
                    </div>
                  </Link>
                );
              })}
            </Col>
            <Col lg="9" className="admin-detail-page">
              <Outlet />
            </Col>
          </>
        ) : (
          <>
            <Col lg="1" className="admin-sidebar">
              {SideBarData.map((data) => {
                return (
                  <Link to={`${data.path}`}>
                    <div className="sidebar-item">
                      <div>{data.icon}</div>
                    </div>
                  </Link>
                );
              })}
            </Col>
            <Col lg="11" className="admin-detail-page">
              <Outlet />
            </Col>
          </>
        )}
      </div>
    </div>
  );
};
const mapStateToProps = (state) => {
  return {
    menuToggle: state.history?.menuToggle,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    setMenuToggle: (menuToggle) => dispatch(setMenuToggle(menuToggle)),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(AdminSideBar);

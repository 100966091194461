import React, { useEffect, useRef, useState } from "react";
import { Modal, ModalHeader, ModalBody } from "reactstrap";
import ReactPlayer from "react-player/lazy";
import Slider from "react-slick";
import pdfImage from "../assets/images/pdfPreview.png";
import Nopreview from "../assets/images/Nopreview.png";
const AllFilesPreview = (props) => {
  const {
    showAllfilesPreview,
    memoryDetails,
    filePreviewIndex,
    closeAllfilesPreview,
  } = props;

  const sliderRef = useRef();

  const [activeSlide, setActiveSlide] = useState(0);

  useEffect(() => {
    setActiveSlide(filePreviewIndex);
  }, []);

  const focusDiv = useRef();

  useEffect(() => {
    if (sliderRef.current?.slickGoTo) {
      sliderRef.current.slickGoTo(activeSlide);
    }
  }, [activeSlide]);

  useEffect(() => {
    if (focusDiv.current) focusDiv.current.focus();
  }, [focusDiv]);

  const settings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  };

  const FilePreview = (props) => {
    const type = props.mimeType.split("/");
    if (
      props.mimeType === "image/jpeg" ||
      props.mimeType === "image/png" ||
      props.mimeType === "image/jpg"
    ) {
      return (
        <img
          src={props.path}
          alt="img"
          style={{
            objectFit: "contain",
            width: "100%",
            margin: "auto",
            height: "360px",
          }}
        />
      );
    }
    if (type[0] === "video") {
      return (
        <ReactPlayer
          url={props.path}
          controls={true}
          className="video-sec"
          style={{
            width: "0px",
            height: "auto",
            background: "black",
          }}
          width="100%"
        />
      );
    }

    // if (props.mimeType === "application/pdf") {
    //   return (
    //     <div>
    //       <a href={props.path} target="_blank" rel="noreferrer">
    //         <img
    //           src={pdfImage}
    //           alt="img"
    //           style={{
    //             objectFit: "contain",
    //             width: "100%",
    //             margin: "auto",
    //             height: "320px",
    //           }}
    //         />
    //         <p>{props.path}</p>
    //       </a>
    //     </div>
    //   );
    // }
    return (
      <img
        style={{
          objectFit: "contain",
          width: "100%",
          margin: "auto",
          height: "320px",
        }}
        src={Nopreview}
        alt="img"
      />
    );
  };

  return (
    <div>
      <Modal
        isOpen={showAllfilesPreview}
        size="lg"
        style={{ maxWidth: "700px", width: "100%" }}
      >
        <ModalHeader toggle={closeAllfilesPreview}>
          <p>Preview</p>
        </ModalHeader>

        <ModalBody>
          <Slider {...settings} {...settings} ref={sliderRef}>
            {memoryDetails?.[0].mediaUrl?.length > 0 &&
              memoryDetails?.[0].mediaUrl.map((url, index) => {
                return (
                  <div key={index} className="vd">
                    <FilePreview
                      path={url.file_path}
                      mimeType={url.mime_type}
                    />
                  </div>
                );
              })}
          </Slider>
        </ModalBody>
      </Modal>
    </div>
  );
};

export default AllFilesPreview;

import React, { useEffect, useState } from "react";
import { useParams, useLocation } from "react-router-dom";
import { Col } from "reactstrap";
import ListDetail from "../list/ListDetail";
import MemoryDetail from "../memory/MemoryDetail";
import { connect } from "react-redux";
import { showhistoryDetailOnMobile } from "../redux";
import { fetchUsersAllMemory } from "../redux";
import { Spinner } from "reactstrap";
import DetailHeader from "./DetailHeader";
import { getType } from "../service/HistoryService";
const UserMemoryDetail = (props) => {
  const location = useLocation();

  const [loading, setloading] = useState(false);

  const [type, settype] = useState(location.state?.type);

  const [memoryNameInEditMode, setmemoryNameInEditMode] = useState(
    location.state?.memoryNameInEditMode
  );

  const [noDataToggle, setnoDataToggle] = useState(true);
  let params = useParams();

  const memoryId = params.memoryId;

  useEffect(() => {
    if (type) {
      settype(location.state?.type);
      setmemoryNameInEditMode(location.state?.memoryNameInEditMode);
      setloading(true);
    } else {
      getType(memoryId)
        .then((response) => {
          if (response.status === 200 && response.data?.length > 0) {
            settype(response.data[0].type);
            setloading(true);
          } else {
            setnoDataToggle(false);
          }
        })
        .catch((err) => {
          console.log(err);
          setnoDataToggle(false);
        });
    }
  }, [memoryId]);

  useEffect(() => {
    props.showhistoryDetailOnMobile();
  }, [memoryId]);

  return (
    <Col lg="9" md="8" className="col-12">
      {!loading && <DetailHeader />}
      {loading ? (
        <>
          {type === "list" ? (
            <ListDetail
              listId={memoryId}
              memoryNameInEditMode={memoryNameInEditMode}
            />
          ) : (
            <MemoryDetail
              memoryId={memoryId}
              showNameInEditMode={memoryNameInEditMode}
            />
          )}
        </>
      ) : noDataToggle ? (
        <div className="custom-loader">
          <Spinner animation="grow" size="sm" />
        </div>
      ) : (
        <div className="custom_no_datafound">
          <p>No Record Found</p>
        </div>
      )}
    </Col>
  );
};

const mapStateToProps = (state) => {
  return {
    userData: state.history?.users,
    showhistoryDetailpage: state.history?.showDetailpage,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    fetchUsersAllMemory: () => dispatch(fetchUsersAllMemory()),
    showhistoryDetailOnMobile: () => dispatch(showhistoryDetailOnMobile()),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(UserMemoryDetail);

import React, { useState, useEffect, useRef } from "react";
import DetailHeader from "../history/DetailHeader";
import {
  reminderHistory,
  newReminderHistory,
} from "../service/ReminderService";
import { toast } from "react-toastify";
import moment from "moment";
import { connect } from "react-redux";
import { Col, Spinner } from "reactstrap";
import { markAsDoneReminder } from "../service/ReminderService";
import { setReminderId } from "../redux";
import InfiniteScroll from "react-infinite-scroll-component";
import ReminderToggle from "./ReminderToggle";
import { showhistoryDetailOnMobile, hidehistoryDetailOnMobile } from "../redux";
import ReminderHeader from "../history/ReminderHeader";
import MonthReminder from "./MonthReminder";
import Month from "./Month";
const ReminderHistory = (props) => {
  /* const "loading" store loading status */
  const [loading, setloading] = useState(true);

  /* const "reminders" store all reminder history */
  const [allReminderHistory, setallReminderHistory] = useState([]);

  /* const "ref1" store refrence */
  const reminderRef = useRef();

  /* const "height" store height of scrollbar */
  const [height, setHeight] = useState(0);

  /* const "scrollTop" store scrollTop value */
  const [scrollTop, setscrollTop] = useState();

  /*cosnt "hasmore" toggle for load more data or not */
  const [hasMore, sethasMore] = useState(true);

  /* const "dataLenght" store lenght of data */
  const [dataLenght, setdataLenght] = useState(0);

  /* cosnt "scrollLoader" loader for scrollbar */
  const [scrollLoader, setscrollLoader] = useState(false);

  /* const "scrollToggle" toggle for loading scroll data */
  const [scrollToggle, setscrollToggle] = useState(true);

  /* cosnt "todayScrollToggle" show and hide todya button */
  const [todayScrollToggle, settodayScrollToggle] = useState(false);

  /* cosnt "rerender" rerender the component */
  const [rerender, setrerender] = useState(true);

  /* const 'startDay' store start date */
  const [startDay, setstartDay] = useState(new Date());

  /* const 'endDay' store end date */
  const [endDay, setendDay] = useState(new Date());

  /*const "nextMonthCount" store count for next month  */
  const [nextMonthCount, setnextMonthCount] = useState(3);

  /*const "previousMonthCount" store count for previous month  */
  const [previousMonthCount, setpreviousMonthCount] = useState(2);

  const [callOnScrolltoggle, setcallOnScrolltoggle] = useState(false);

  const [oneYearCounter, setoneYearCounter] = useState(0);

  const [intialscrollToggle, setintialscrollToggle] = useState(false);

  const [todayDateToggle, settodayDateToggle] = useState(false);

  useEffect(() => {
    props.showhistoryDetailOnMobile();
    document.body.style.overscrollBehavior = "contain";
   
    return () => {
      document.body.style.overscrollBehavior = "auto";
    }
  }, []);

 
  

  useEffect(() => {
    if (allReminderHistory.length > 0) {
      let dataLenght = 0;
      allReminderHistory.map((ReminderHistory) => {
        dataLenght = dataLenght + ReminderHistory.reminder?.length;
      });
      setdataLenght(dataLenght);
    }
  }, [allReminderHistory]);

  useEffect(() => {
    let date = moment(new Date()).format("MMM YYYY");
    if (allReminderHistory.length > 0) {
      allReminderHistory.map((ReminderHistory) => {
        if (ReminderHistory.monthYear == date) {
          ReminderHistory.reminder.map((reminder) => {
           
            if (
              moment(reminder.reminder_time).format("DD-MM-YYYY") ===
              moment(new Date()).format("DD-MM-YYYY")
            ) {
              settodayDateToggle(true);
            }
          });
        }
      });
    }
  }, [allReminderHistory]);

  useEffect(() => {
    let date = new Date();
    let startDay = new Date(
      date.getFullYear(),
      date.getMonth() - previousMonthCount,
      1
    );
    let endDay = new Date(
      date.getFullYear(),
      date.getMonth() + nextMonthCount,
      0
    );
    setstartDay(startDay);
    setendDay(endDay);
    getReminderHistory(startDay, endDay);
  }, []);

  useEffect(() => {
    if (loading === false) getListSize();
  }, [loading, allReminderHistory]);

  useEffect(() => {
    window.onpopstate = (e) => {
      props.hidehistoryDetailOnMobile();
    };
  });

  useEffect(() => {
    onscroll(scrollTop);
  }, [callOnScrolltoggle]);

  /**
   * This function will fetch all reminder of user
   * @param {string} startDay 
   * @param {string} endDay 
   * @param {string} addType 
   */
  const getReminderHistory = (startDay, endDay, addType) => {
    const userId = localStorage.getItem("userId");
    const dataObj = {
      userId: userId,
      startDate: moment(startDay).format("YYYY-MM-DD"),
      endDate: moment(endDay).format("YYYY-MM-DD"),
      requestedTimeZone: "+05:30",
    };
    newReminderHistory(dataObj)
      .then((response) => {
        if (response.status == 200) {
          if (addType == "addAtStart") {
            if (response.data.length === 0 && oneYearCounter < 12) {
              const updateCount = oneYearCounter + 1;
              setoneYearCounter(updateCount);
              setcallOnScrolltoggle(!callOnScrolltoggle);
            } else {
              setscrollLoader(false);
              setoneYearCounter(0);
            }
            const updateResponse = [...response.data, ...allReminderHistory];
           
            setallReminderHistory(updateResponse);
          } else {
            if (response.data.length === 0) {
              sethasMore(false);
            }
            const updateResponse = [...allReminderHistory, ...response.data];
       
            setallReminderHistory(updateResponse);
          }
          setloading(false);
        } else {
          setscrollLoader(false);
          setoneYearCounter(0);
          toast.error("SomeThing went wrong", {
            className: "toast-container_OTP",
            position: "top-right",
            autoClose: 3000,
          });
        }
      })
      .catch(() => {
        setscrollLoader(false);
        setoneYearCounter(0);
        toast.error("SomeThing went wrong", {
          className: "toast-container_OTP",
          position: "top-right",
          autoClose: 3000,
        });
      });
  };

  // This function calculates width and height of the list
  const getListSize = (event) => {
    let newHeight;
    newHeight = reminderRef.current.clientHeight;
    if (scrollTop?.target.scrollTop === 0) {
      scrollTop.target.scrollTop = newHeight - height - 80;
    }
    setHeight(newHeight);
  };

  /**
   * This function will fetch more data of reminder
   */
  const fetchMoreReminder = () => {
   const updatePreviousMonthCount = previousMonthCount + 1;
    let date = new Date();
    let startDay = new Date(
      date.getFullYear(),
      date.getMonth() - updatePreviousMonthCount,
      1
    );
    let endDay = new Date(
      date.getFullYear(),
      date.getMonth() - (updatePreviousMonthCount - 1),
      0
    );
    setpreviousMonthCount(updatePreviousMonthCount);
    getReminderHistory(startDay, endDay, "addAtLast");
  };

  /**
   * This function called on changeing every position of srcoll
   * @param {object} event 
   */
  const onscroll = (event) => {
    if (event?.target.scrollTop === 0 && intialscrollToggle) {
      setscrollLoader(true);
      const updatenextMonthCount = nextMonthCount + 1;
      setnextMonthCount(updatenextMonthCount);
      let date = new Date();
      let startDay = new Date(
        date.getFullYear(),
        date.getMonth() + nextMonthCount,
        1
      );
      let endDay = new Date(
        date.getFullYear(),
        date.getMonth() + updatenextMonthCount,
        0
      );
      setscrollTop(event);
      getReminderHistory(startDay, endDay, "addAtStart", event);
    } else {
      if (event?.target.scrollTop === 0) {
        event.target.scrollTop = event.target.scrollTop + 10;
        setintialscrollToggle(true);
      }
    }
  };

  /**
   * This function will mark as done the reminder
   * @param {string} reminderId 
   * @param {number} reminderTime 
   * @param {string} monthYear 
   * @param {number} markAsDoneindex 
   */
  const handleMarkAsDone = (
    reminderId,
    reminderTime,
    monthYear,
    markAsDoneindex
  ) => {
    allReminderHistory.map((reminderHistory, index) => {
      if (monthYear === reminderHistory.monthYear) {
        if (reminderHistory.reminder?.length === 1) {
          allReminderHistory.splice(index, 1);
        } else {
          reminderHistory.reminder.map((reminder, index) => {
            if (markAsDoneindex === index) {
              reminderHistory.reminder.splice(index, 1);
            }
          });
        }
      }
    });
    setallReminderHistory(allReminderHistory);
    setrerender(!rerender);
    const dataObj = {
      reminderId: reminderId,
      doneDates: reminderTime,
    };
    markAsDoneReminder(dataObj)
      .then((response) => {
        if (response.status === 200) {
        } else {
          toast.error("Some Thing went wrong6", {
            className: "toast-container_OTP",
            position: "top-right",
            autoClose: 3000,
          });
        }
      })
      .catch((error) => {
        toast.error("Some Thing went wrong6", {
          className: "toast-container_OTP",
          position: "top-right",
          autoClose: 3000,
        });
      });
  };

  return (
    <Col lg="9" md="8" className="col-12">
      <ReminderHeader />
      <div className="reminder-history-header">Reminders</div>
      {loading ? (
        <div className="custom-reminder-loader reminder-history-header-loader">
          <Spinner animation="grow" size="sm" />
        </div>
      ) : (
        <div>
          {allReminderHistory.length > 0 && todayDateToggle ? (
            <div
              className="d-flex justify-content-end"
              style={{ margin: "0px 20px" }}
            >
              <button
                onClick={() => settodayScrollToggle(true)}
                className="d-flex align-items-center today_button"
              >
                <i
                  className="far fa-clock"
                  style={{
                    marginRight: "3px",
                    fontSize: "12px",
                  }}
                ></i>
                <p>Today</p>
              </button>
            </div>
          ) : (
            ""
          )}
          {allReminderHistory.length && scrollLoader ? (
            <p style={{ display: "flex", justifyContent: "center" }}>
              Loading...
            </p>
          ) : (
            ""
          )}
          <div
            id={
              allReminderHistory.length === 0
                ? "scrollableDiv_no_reminder"
                : "scrollableDiv"
            }
          >
            <div ref={reminderRef}>
              <InfiniteScroll
                className={
                  allReminderHistory.length && dataLenght < 5
                    ? "data-reduce"
                    : ""
                }
                dataLength={1000000}
                next={fetchMoreReminder}
                hasMore={hasMore}
                loader={
                  <p style={{ display: "flex", justifyContent: "center" }}>
                    Loading...
                  </p>
                }
                scrollableTarget="scrollableDiv"
                initialScrollY={5}
                onScroll={onscroll}
              >
                {allReminderHistory.length > 0 ? (
                  allReminderHistory.map((monthReminder, index) => {
                    return (
                      <div className="reminder_history_container" key={index}>
                        <Month
                          scrollToggle={scrollToggle}
                          setscrollToggle={setscrollToggle}
                          todayScrollToggle={todayScrollToggle}
                          settodayScrollToggle={settodayScrollToggle}
                          monthReminder={monthReminder}
                          handleMarkAsDone={handleMarkAsDone}
                        />
                      </div>
                    );
                  })
                ) : (
                  <div className="custom_no_datafound">
                    <p className="d-flex justify-content-center">
                      No Reminder Found
                    </p>
                  </div>
                )}
              </InfiniteScroll>
            </div>
          </div>
        </div>
      )}
    </Col>
  );
};

const mapStateToProps = (state) => {
  return {
    showhistoryDetailpage: state.history?.showDetailpage,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    showhistoryDetailOnMobile: () => dispatch(showhistoryDetailOnMobile()),
    hidehistoryDetailOnMobile: () => dispatch(hidehistoryDetailOnMobile()),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(ReminderHistory);

import React from "react";
import {
  Button,
  Label,
  FormGroup,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from "reactstrap";
const DeleteItemModal = (props) => {
  const { showDeleteItemModal,deletedItemId,handledeleteItem,deleteLoader,closedDeleteItemModal} = props;

  const onSubmit = () => {
    handledeleteItem(deletedItemId)
  };
  return (
    <div>
      <Modal isOpen={showDeleteItemModal}>
        <ModalHeader toggle={closedDeleteItemModal}>
          <h5 className="modal-title f-w-600" id="exampleModalLabel2">
          Confirmation
          </h5>
        </ModalHeader>
        <ModalBody>
          <FormGroup className="form-group mb-3 row">
            <Label className="col-xl-12 col-sm-12 mb-0">
             Do you want to delete this Item ?
            </Label>
          </FormGroup>
        </ModalBody>
        <ModalFooter>
          <Button
            type="submit"
            className="custom-btn-fill"
            size="sm"
            onClick={onSubmit}
            disabled={deleteLoader}
          >
           {deleteLoader?"Deleting":"Delete"}
          </Button>
          <Button onClick={closedDeleteItemModal} className="custom-btn" size="sm">
            Close
          </Button>
        </ModalFooter>
      </Modal>
    </div>
  );
};

export default DeleteItemModal;


import Api from "../Api";

export const allMemory = (userdata) => {
  return Api.post("history", userdata)
    .then((res) => res.data)
    .catch((error) => error);
};

export const createHistory = (dataObj) => {
  return Api.post("create-list", dataObj)
    .then((res) => res.data)
    .catch((error) => error);
};

export const historyDetail = (uuid) => {
  return Api.get(`memoryDetails?memoryId=${uuid}`)
    .then((res) => res.data)
    .catch((error) => error);
};

export const deleteMemory = (memoryId) => {
return Api.delete(`memory?memoryId=${memoryId}`)
    .then((res) => res.data)
    .catch((error) => error);
};

export const getType = (memoryId)=>{
  return Api.get(`getType?memoryId=${memoryId}`)
  .then((res) => res.data)
  .catch((error) => error);
}
import React, { useState, useEffect } from "react";
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Spinner,
  Button,
} from "reactstrap";
import ReactPlayer from "react-player/lazy";
import vedioImage from "../assets/images/video2.png";
import pdfImage from "../assets/images/pdfPreview.png";
import Nopreview from "../assets/images/Nopreview.png";
import Cropper from "react-cropper";
import "cropperjs/dist/cropper.css";
import TextareaAutosize from "react-textarea-autosize";
import CreateAllFileRow from "./CreateAllFileRow";
import ClickFilePreview from "./ClickFilePreview";
import { Line } from 'rc-progress';
const UploadFiles = (props) => {
  const {
    showUploadFilesModal,
    filesLoader,
    allReadFilesObject,
    files,
    onChangeDocument,
    uploadDocument,
    disableButton,
    disablebtn,
    percentCompleted,
    closeUploadFilesModal,
  } = props;

 /* const "clickFilePath" click file path */
  const [clickFilePath, setclickFilePath] = useState();

  /* const "clickFileMimeType"  store MimeType of click file */
  const [clickFileMimeType, setclickFileMimeType] = useState();

  /* const "rerender" render the component */
  const [rerender, setrerender] = useState(true);

  /* const  "loader" toggle for loading data*/
  const [loader, setloader] = useState(true);

  /* const "showCroppedImage" show cropped image */
  const [showCroppedImage, setshowCroppedImage] = useState(false);

  /* const  "editImageIndex" store edit image index*/
  const [editImageIndex, seteditImageIndex] = useState(0);

  /*const "editImageObject" store edit image object  */
  const [editImageObject, seteditImageObject] = useState();

  /* const "cropper" store cropper of image */
  const [cropper, setCropper] = useState();

  const [captionValue, setcaptionValue] = useState("");

  useEffect(() => {
    if (allReadFilesObject.length > 0) {
      setclickFilePath(allReadFilesObject[0].path);
      setclickFileMimeType(allReadFilesObject[0].mime_type);
      seteditImageObject(allReadFilesObject[0]);
      seteditImageIndex(0);
      setloader(false);
    }
  }, [allReadFilesObject]);

  useEffect(() => {
    if (cropper) {
      cropper.setCropBoxData({ top: 0, left: 0, width: "40%" });
    }
  }, [cropper]);

  /**
   * This function show click file
   * @param {string} filePath
   * @param {object} file
   * @param {number} index
   */
  const showClickFile = (filePath, file, index) => {
    setcaptionValue(file.caption);
    setshowCroppedImage(false);
    setclickFilePath(filePath);
    setclickFileMimeType(file.mime_type);
    seteditImageObject(file);
    seteditImageIndex(index);
  };

  /**
   * This function will remove file from file array
   * @param {string} ImagePath
   * @param {number} index
   */
  const removeFile = (ImagePath, index) => {
    allReadFilesObject.splice(index, 1);
    files.splice(index, 1);
    if (allReadFilesObject.length === 0) {
      closeUploadFilesModal();
      return;
    }
    if (index === 0 && ImagePath === clickFilePath) {
      setclickFilePath(allReadFilesObject[index].path);
      setclickFileMimeType(allReadFilesObject[index].mime_type);
      setcaptionValue(allReadFilesObject[index].caption);
      seteditImageIndex(index);
    } else if (
      index === allReadFilesObject.length &&
      ImagePath === clickFilePath
    ) {
      setclickFilePath(allReadFilesObject[index - 1].path);
      setclickFileMimeType(allReadFilesObject[index - 1].mime_type);
      setcaptionValue(allReadFilesObject[index - 1].caption);
      seteditImageIndex(index - 1);
    } else {
      if (index === editImageIndex) {
        setclickFilePath(allReadFilesObject[index].path);
        setclickFileMimeType(allReadFilesObject[index].mime_type);
        setcaptionValue(allReadFilesObject[index].caption);
        seteditImageIndex(index);
      } else if (index < editImageIndex) {
        seteditImageIndex((editImageIndex) => editImageIndex - 1);
      }
    }
    setrerender(!rerender);
  };

  /**
   * This function will open image in edit mode
   */
  const editImage = () => {
    setshowCroppedImage(true);
  };

  /**
   * This funciton will manage cropper instanse
   * @param {object} instance
   */

  const manageCropper = (instance) => {
    setCropper(instance);
  };

  /**
   * This fuction will save edit image
   * @param {number} index
   * @param {obj} image
   */
  const saveEditImage = (index, image) => {
    let imageObj = {};
    imageObj.path = cropper.getCroppedCanvas(image.mime_type).toDataURL();
    imageObj.mime_type = image.mime_type;
    imageObj.name = image.name;
    if (typeof cropper !== "undefined") {
      allReadFilesObject.splice(index, 1, imageObj);
    }
    dataURLtoFile(
      cropper.getCroppedCanvas(image.mime_type).toDataURL(),
      index,
      image.name,
      imageObj
    );
  };

  /**
   * This funciton will convert data url to file
   * @param {string} dataurl
   * @param {number} index
   * @param {string} filename
   * @param {obj} imageObj
   */
  const dataURLtoFile = (dataurl, index, filename, imageObj) => {
    let arr = dataurl.split(","),
      mime = arr[0].match(/:(.*?);/)[1],
      bstr = atob(arr[1]),
      n = bstr.length,
      u8arr = new Uint8Array(n);

    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }
    let croppedImage = new File([u8arr], filename, { type: mime });
    files.splice(index, 1, croppedImage);
    showClickFile(imageObj.path, imageObj, index);
  };

  /**
   * This function will rotate the image
   */
  const rotateImage = () => {
    if (cropper) {
      cropper.rotate(90);
      setrerender(!rerender);
    }
  };

  /**
   * This function will send image server
   */
  const onSubmit = () => {
    if (files.length > 0) {
      uploadDocument(files, allReadFilesObject);
    }
  };

  const handleCaption = (e, readFilesObject) => {
    setcaptionValue(e.target.value);
    readFilesObject.caption = e.target.value;
  };

  return (
    <div>
      <Modal
        isOpen={showUploadFilesModal}
        size="lg"
        style={{ maxWidth: "700px", width: "100%" }}
      >
        <ModalHeader toggle={closeUploadFilesModal}>Upload Files</ModalHeader>
        <ModalBody style={{background:"#f7f7f7"}}>
          {filesLoader || loader ? (
            <div className="custom-file-loader">
              <Spinner animation="grow" size="sm" />
            </div>
          ) : (
            <div>
              {clickFileMimeType === "image/jpeg" ||
              clickFileMimeType === "image/png" ||
              clickFileMimeType === "image/jpg" ? (
                <div className="img-action mt-1">
                  <Button
                    disabled={disableButton || filesLoader}
                    className="custom-btn mr-1 px-2 py-1"
                    onClick={() => editImage()}
                  >
                    <i className="fas fa-crop-alt"></i>
                  </Button>
                </div>
              ) : (
                ""
              )}

              {showCroppedImage ? (
                <div>
                  <Cropper
                    movable={true}
                    className="clickImage_container"
                    zoomable={true}
                    dragMode="move"
                    zoomOnTouch={true}
                    zoomOnWheel={true}
                    initialAspectRatio={1}
                    src={clickFilePath}
                    viewMode={2}
                    rotatable={true}
                    minCropBoxHeight={40}
                    minCropBoxWidth={40}
                    background={true}
                    responsive={true}
                    cropBoxMovable={false}
                    autoCrop={true}
                    autoCropArea={1}
                    modal={true}
                    checkOrientation={false}
                    onInitialized={(instance) => manageCropper(instance)}
                    guides={true}
                    center={true}
                    highlight={false}
                    // dragMode="move"
                  />
                  <div className="text-center">
                    <Button
                      onClick={rotateImage}
                      disabled={disableButton || filesLoader}
                      className="btn-sm custom-btn"
                    >
                      <i className="fas fa-sync"></i>
                    </Button>
                    <Button
                      onClick={() =>
                        saveEditImage(editImageIndex, editImageObject)
                      }
                      disabled={disableButton || filesLoader}
                      className="btn-sm custom-btn"
                    >
                      <i className="fas fa-check"></i>
                    </Button>
                  </div>
                </div>
              ) : (
                <div className="filesPreview_container">
                  <ClickFilePreview
                    clickFileMimeType={clickFileMimeType}
                    clickFilePath={clickFilePath}
                  />
                </div>
              )}
              {allReadFilesObject.map((readFilesObject, index) => {
                return (
                  <div className="caption-container">
                    {index === editImageIndex && (
                      <TextareaAutosize
                        placeholder="Add a caption"
                        minRows={1.5}
                         style={{padding:"5px 10px"}}
                        value={captionValue}
                        onChange={(e) => handleCaption(e, readFilesObject)}
                      />
                    )}
                  </div>
                );
              })}
              <div className="files_container">
                {allReadFilesObject.map((file, index) => {
                  return (
                    <div key={index}>
                      <div
                        className="files mx-2 my-2"
                        style={{
                          border:
                            file.path === clickFilePath &&
                            editImageIndex === index
                              ? "3px solid #636ac6"
                              : "none",
                        }}
                      >
                        <span
                          onClick={() => showClickFile(file.path, file, index)}
                        >
                          <CreateAllFileRow
                            path={file.path}
                            mimeType={file.mime_type}
                          />
                        </span>
                        <span onClick={() => removeFile(file.path, index)}>
                          <i
                            className="fas fa-times"
                            type="button"
                            value=" Click "
                          />
                        </span>
                      </div>
                    </div>
                  );
                })}
                <div className="custom_input">
                  <label>
                    <input
                      type="file"
                      accept=".pdf,video/*,image/png,image/jpg,image/jpeg"
                      onChange={onChangeDocument}
                      multiple
                      disabled={disableButton || filesLoader}
                    />
                    <i className="fas fa-plus" />
                  </label>
                </div>
              </div>
            </div>
          )}
          <div>
            {disableButton&&
            <div className="progress_bar_container">
            <Line percent={percentCompleted} strokeWidth="1" strokeColor="#636ac6" />
            <p>Progress: {percentCompleted}%</p>
            </div>
            }
           
            </div>
        </ModalBody>
        <ModalFooter>
          <Button
            onClick={onSubmit}
            disabled={disableButton || filesLoader}
            className="custom-btn-fill"
            size="sm"
          >
            {disableButton ? "Uploading..." : "Upload"}
          </Button>
          <Button
            onClick={closeUploadFilesModal}
            className="custom-btn"
            size="sm"
          >
            Cancel
          </Button>
        </ModalFooter>
      </Modal>
    </div>
  );
};

export default UploadFiles;

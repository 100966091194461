import React, { useState, useEffect } from "react";
import ReminderDropDown from "./ReminderDropDown";
import TimeAndDatePicker from "./TimeAndDatePicker";
import { setReminderTime } from "../redux";
import { connect } from "react-redux";
import DeleteReminderModal from "./DeleteReminderModal";
import { toast } from "react-toastify";
import { deleteReminder } from "../service/ReminderService";
const Reminder = (props) => {
  const { getMemoryDetail } = props;

  /* const "dropdownOpen" open and close set reminder dropdown */
  const [showReminderDropDown, setshowReminderDropDown] = useState(false);

  /* const "showPickdateTimeDropDown" show and hide custome custome reminder set*/
  const [showPickDateAndTime, setshowPickDateAndTime] = useState(false);

  /* const "showDeleteReminderModal" will show delete reminder modal */
  const [showDeleteReminderModal, setshowDeleteReminderModal] = useState(false);

  /* const "deleteLoader" store loading status */
  const [deleteLoader, setdeleteLoader] = useState(false);

  useEffect(() => {
    if (props.reminderTime) {
      setshowPickDateAndTime(true);
    } else {
      setshowPickDateAndTime(false);
    }
  }, [props.reminderTime]);

  /**
   * This function is reponsible for showing and hideing reminder dropdown
   */
  const handleShowReminderDropDown = () => {
    setshowReminderDropDown(!showReminderDropDown);
  };

  /**
   * This function will show and hide "time and date pick" screen on reminder dropdwon
   */
  const handleShowPickTimeAndDate = () => {
    setshowPickDateAndTime(!showPickDateAndTime);
  };

  /**
   *
   * @returns This function return device type its mobile,laptop,desktop
   */
  const deviceType = () => {
    const ua = navigator.userAgent;
    if (/(tablet|ipad|playbook|silk)|(android(?!.*mobi))/i.test(ua)) {
      return "tablet";
    } else if (
      /Mobile|Android|iP(hone|od)|IEMobile|BlackBerry|Kindle|Silk-Accelerated|(hpw|web)OS|Opera M(obi|ini)/.test(
        ua
      )
    ) {
      return "mobile";
    }
    return "desktop";
  };

  /**
   * This Function will delete reminder
   */
  const handleDeleteRemider = () => {
    setdeleteLoader(true);
    deleteReminder(props.reminderId)
      .then((response) => {
        setdeleteLoader(false);
        if (response.status === 200) {
          getMemoryDetail();
          setshowDeleteReminderModal(false);
        } else {
          setshowDeleteReminderModal(false);
          toast.error("Something went wrong", {
            className: "toast-container_OTP",
            position: "top-right",
            autoClose: 3000,
          });
        }
      })
      .catch((error) => {
        toast.error("Something went wrong", {
          className: "toast-container_OTP",
          position: "top-right",
          autoClose: 3000,
        });
      });
  };

  return (
    <div style={{ position: "relative" }}>
      <div
        onClick={handleShowReminderDropDown}
        className="reminder_dropdown_icon"
      >
        {props.reminderTime ? (
          <i className="fas fa-bell"></i>
        ) : (
          <i className="far fa-bell"></i>
        )}
      </div>
      {showReminderDropDown && (
        <div
          className="reminder_dropdown"
          style={{
            width: deviceType() !== "mobile" ? "350px" : "300px",
            right: deviceType() !== "mobile" ? 10 : -220,
          }}
        >
          {showPickDateAndTime ? (
            <TimeAndDatePicker
              getMemoryDetail={getMemoryDetail}
              handleShowReminderDropDown={handleShowReminderDropDown}
              handleShowPickTimeAndDate={handleShowPickTimeAndDate}
              setshowDeleteReminderModal={setshowDeleteReminderModal}
            />
          ) : (
            <ReminderDropDown
              getMemoryDetail={getMemoryDetail}
              handleShowReminderDropDown={handleShowReminderDropDown}
              handleShowPickTimeAndDate={handleShowPickTimeAndDate}
            />
          )}
        </div>
      )}
      {showDeleteReminderModal && (
        <DeleteReminderModal
          showDeleteReminderModal={showDeleteReminderModal}
          deleteLoader={deleteLoader}
          handleDeleteReminder={handleDeleteRemider}
          closeDeleteReminderModal={() => setshowDeleteReminderModal(false)}
        />
      )}
    </div>
  );
};
const mapStateToProps = (state) => {
  return {
    reminderTime: state.history?.reminderTime,
    reminderId: state.history?.reminderId,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    setReminderTime: (reminderTime) => dispatch(setReminderTime(reminderTime)),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(Reminder);

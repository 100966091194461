import React from "react";
import pdfImage from "../assets/images/pdfPreview.png";
import ReactPlayer from "react-player/lazy";
import Nopreview from "../assets/images/Nopreview.png";
const ClickFilePreview = (props) => {
  console.log("ClickFilePreview");
  const type = props.clickFileMimeType.split("/");
  if (
    props.clickFileMimeType === "image/jpeg" ||
    props.clickFileMimeType === "image/png" ||
    props.clickFileMimeType === "image/jpg"
  ) {
    return (
      <div>
        <div className="ImagefilesPreview">
          <img src={props.clickFilePath} alt="img" />
        </div>
      </div>
    );
  }
  if (type[0] === "video") {
    return (
      <ReactPlayer
        url={props.clickFilePath}
        controls={true}
        className="video-sec"
        style={{
          width: "100%!important",
          height: "auto",
          background: "black",
        }}
      />
    );
  }
  if (props.clickFileMimeType === "application/pdf") {
    return (
      <div className="ImagefilesPreview">
        <img
          src={pdfImage}
          alt="img"
          style={{ objectFit: "contain", width: "300px" }}
        />
      </div>
    );
  }

  return (
    <div className="ImagefilesPreview">
      <img
        src={Nopreview}
        style={{ objectFit: "contain", width: "300px" }}
        alt="img"
      />
    </div>
  );
};

export default React.memo(ClickFilePreview);


import Api from '../Api'
export const addReminder = (dataObj)=>{
  return Api.post("reminder",dataObj).then(res=>res.data).catch(err=>err)
}

export const updateReminder = (dataObj)=>{
  return Api.put("reminder",dataObj).then(res=>res.data).catch(err=>err)
}

export const reminderHistory = (dataObj)=>{
  return Api.post("reminder-history",dataObj).then(res=>res.data).catch(err=>err)
}

export const newReminderHistory = (dataObj)=>{
  return Api.post("reminder-history",dataObj).then(res=>res.data).catch(err=>err)
}

export const deleteReminder = (reminderId)=>{
  return Api.delete(`reminder?reminderId=${reminderId}`).then(res=>res.data).catch(err=>err)
}

export const markAsDoneReminder = (dataObj)=>{
  return Api.put("markDoneReminder",dataObj).then(res=>res.data).catch(err=>err)
}


import React, { useState } from "react";
import TextareaAutosize from "react-textarea-autosize";
import SelectUserModal from "./SelectUserModal";
import { Button, Table } from "reactstrap";
import { sentNotification } from "../../service/AdminServices";
import DeleteUser from "./DeleteUser";
const Notification = () => {
  const [showAllUserModal, setshowAllUserModal] = useState(false);

  const [disablebtn, setdisablebtn] = useState(false);

  const [message, setmessage] = useState("");

  const [allSelectedUser, setallSelectedUser] = useState([]);

  const [showDeleteUserModal, setshowDeleteUserModal] = useState(false);

  const [deletedUserIndex, setdeletedUserIndex] = useState(0);

  const [render, setrerender] = useState(true);

  /**
   * This function will capitalize the first lettar
   * @param {string} string
   * @returns
   */
  function capitalizeFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
  }

  const handleSendNotification = () => {
    const userIds = allSelectedUser.map((user) => user.user_id);
    const obj = {};
    obj.userIds = userIds;
    obj.message = message;
    console.log(obj);
    sentNotification(obj)
      .then((res) => {
        console.log("res", res);
      })
      .catch((error) => {
        console.log("error", error);
      });
  };

  const openDeleteUserModal = (index) => {
    setdeletedUserIndex(index);
    setshowDeleteUserModal(true);
  };

  const handleDeletedUser = (index) => {
    allSelectedUser.splice(index, 1);
    setrerender(!render);
    setshowDeleteUserModal(false);
  };
  
  return (
    <>
      <div className="admin-notification-detail-page-container">
        <TextareaAutosize
          placeholder="Write your message..."
          maxRows="4"
          minRows="4"
          value={capitalizeFirstLetter(message)}
          onChange={(e) => setmessage(e.target.value)}
        />

        <div className="send-notification-btn">
          <Button size="sm" onClick={() => setshowAllUserModal(true)}>
            Select User
          </Button>
          <Button
            size="sm"
            disabled={!message.length > 0 || !allSelectedUser.length > 0}
            onClick={handleSendNotification}
          >
            Send Notification
          </Button>
        </div>

        {showAllUserModal && (
          <SelectUserModal
            showAllUserModal={showAllUserModal}
            setallSelectedUser={setallSelectedUser}
            disablebtn={disablebtn}
            closeAllUserModal={() => setshowAllUserModal(false)}
          />
        )}
      </div>

      {allSelectedUser.length > 0 && (
        <div className="select-user-table">
          <Table responsive bordered hover>
            <thead>
              <tr>
                <th>User Id</th>
                <th>First Name</th>
                <th>Last Name</th>
                <th>Phone Number</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {allSelectedUser.map((user, index) => {
                return (
                  <tr>
                    <td>{user.user_id}</td>
                    <td>{user.first_name}</td>
                    <td>{user.last_name}</td>
                    <td>{user.phone_number}</td>
                    <td>
                      <span
                        className="delete-user"
                        onClick={() => openDeleteUserModal(index)}
                      >
                        <i class="fa fa-trash" aria-hidden="true"></i>
                      </span>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </Table>
        </div>
      )}
      {showDeleteUserModal && (
        <DeleteUser
          showDeleteUserModal={showDeleteUserModal}
          deletedUserIndex={deletedUserIndex}
          handleDeletedUser={handleDeletedUser}
          closeDeleteUserModal={() => setshowDeleteUserModal(false)}
        />
      )}
    </>
  );
};

export default Notification;

import React, { useState, useEffect } from "react";
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import Cropper from "react-cropper";
const AddItemImageModal = (props) => {
  const {
    showModal,
    fileObject,
    setfileObject,
    setFile,
    disableEditbtn,
    disableSavebtn,
    sendImage,
    closeshowModal,
  } = props;
  

  const [showCroppedImage, setshowCroppedImage] = useState(false);

  const [imagePath, setimagePath] = useState();

  /* const "cropper" store cropper of image */
  const [cropper, setCropper] = useState();

  useEffect(() => {
    setimagePath(fileObject.path);
  }, [fileObject]);

  /**
   * This funciton will save edit image
   * @param {number} index
   * @param {string} image
   */
  const saveEditImage = (image) => {
    console.log("image", image);
    let imageObj = {};
    imageObj.path = cropper.getCroppedCanvas(image.mime_type).toDataURL();
    imageObj.mime_type = image.mime_type;
    imageObj.name = image.name;
    if (typeof cropper !== "undefined") {
      console.log("imageObj",imageObj)
      setfileObject(imageObj);
    }
    dataURLtoFile(
      cropper.getCroppedCanvas(image.mime_type).toDataURL(),
      image.name,
    );
  };

  /**
   * This function will convert dataURL to file
   * @param {string} dataurl
   * @param {number} index
   * @param {string} filename
   * @param {object} imageObj
   */
  const dataURLtoFile = (dataurl, filename) => {
    let arr = dataurl.split(","),
      mime = arr[0].match(/:(.*?);/)[1],
      bstr = atob(arr[1]),
      n = bstr.length,
      u8arr = new Uint8Array(n);
    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }
    let croppedImage = new File([u8arr], filename, { type: mime });
    console.log("croppedImage", croppedImage);
    setFile(croppedImage);
    setshowCroppedImage(false)
  };

  /**
   * This function will open image in edit mode
   */
  const editImage = () => {
    if (showCroppedImage === false) {
      setshowCroppedImage(true);
    } else {
      setshowCroppedImage(false);
    }
  };

  /**
   * This funciton will manage cropper
   * @param {object} instance
   */
  const manageCropper = (instance) => {
    setCropper(instance);
  };

  /**
   * This funciton will rotate the image
   */
  const rotateImage = () => {
    if (cropper) {
      cropper.rotate(90);
    }
  };


  const onSubmit= ()=>{
    sendImage()
  }

  return (
    <div>
      <Modal
        isOpen={showModal}
        size="lg"
        style={{ maxWidth: "700px", width: "100%" }}
      >
        <ModalHeader toggle={closeshowModal}>
          <p>Share List</p>
        </ModalHeader>
        <ModalBody>
          {
            <div className="img-action mt-1">
              <span
                className="custom-btn mr-1"
                disabled={disableEditbtn}
                onClick={() => editImage()}
              >
                <i className="fas fa-crop-alt"></i>
              </span>
            </div>
          }
          {showCroppedImage ? (
            <div>
              <Cropper
                className="clickImage_container"
                movable={true}
                zoomable={true}
                dragMode="move"
                zoomOnTouch={true}
                zoomOnWheel={true}
                initialAspectRatio={1}
                src={imagePath}
                viewMode={2}
                rotatable={true}
                minCropBoxHeight={40}
                minCropBoxWidth={40}
                background={true}
                responsive={true}
                cropBoxMovable={false}
                autoCrop={true}
                autoCropArea={1}
                modal={true}
                checkOrientation={false} // https://github.com/fengyuanchen/cropperjs/issues/671
                onInitialized={(instance) => manageCropper(instance)}
                guides={true}
                center={true}
                highlight={false}
                // dragMode="move"
              />
              <div className="text-center">
                <Button onClick={rotateImage} className="btn-sm custom-btn">
                  <i className="fas fa-sync"></i>
                </Button>
                <Button
                  onClick={() => saveEditImage(fileObject)}
                  className="btn-sm custom-btn"
                >
                  <i className="fas fa-check"></i>
                </Button>
              </div>
            </div>
          ) : (
            <div className="clickImage_container">
              <img src={imagePath} alt="img" />
            </div>
          )}
        </ModalBody>
        <ModalFooter>
          <Button
            onClick={onSubmit}
            disabled={disableSavebtn}
            className="custom-btn-fill"
            size="sm"
          >
            {disableSavebtn ? "Uploading..." : "Upload"}
          </Button>
          <Button
            onClick={closeshowModal}
            className="custom-btn"
            size="sm"
          >
            {" "}
            Close
          </Button>
        </ModalFooter>
      </Modal>
    </div>
  );
};

export default AddItemImageModal;

import React from "react";
import logo from "../assets/images/NewLogo.png";
const TermsAndCondition = () => {
  return (
    <div className="container">
      <div className="TermsAndCondition">
        <a href={"https://tootle.ai"} target="_blank" rel="noreferrer">
          <h4 className="mb-4">
            <img src={logo} width="180" />
          </h4>
        </a>
        <h3>Terms of Service</h3>
        <div>
          <h5>General</h5>
          <p>
            These Terms of Use govern, together with the Privacy Policy, your
            use of the Tootle application (the ”Application”) and related
            services provided by us. By using the Application, you accept and
            consent to these Terms and Conditions (“Terms”). Thus, it is
            important that you carefully read through these Terms. If you do not
            agree to these Terms, you should not use the Application.
          </p>
        </div>
        <div>
          <h5>Intellectual Property and Rights </h5>
          <p>
            All rights in and to the Services, including any trademarks, service
            marks, trade names and copyrighted content (collectively
            “Intellectual Property”) presented within the Application are the
            property of Tootle.ai. and/or third parties. You agree not to use
            Intellectual Property for any other purposes except for your use of
            the Service unless required otherwise by applicable mandatory law.
          </p>
        </div>
        <div>
          <h5>Disclaimer of Warranty and limitation of liability</h5>
          <p>
            No guarantees as to the suitability of this app for the user, or for
            any of its functionality, product price, accuracy, or usefulness.
          </p>
          <p>
            We reserve the right to change this app or to stop offering it.
            Tootle.ai will not be held responsible if this app is changed,
            discontinued, or otherwise made unusable and data is damaged or lost
            as a result, or if the device itself is damaged, or if the device
            incurred damage due to another app.
          </p>
          <p>
            The Application and all of its content is provided on an ”As Is” and
            ”As available” basis and we do not provide any assurances of the
            availability or usability by you of the Application. We shall not be
            liable for any errors in any content or for any actions taken in
            reliance thereon. Moreover, we shall not be liable to you for any
            interception of online communications, software or hardware problems
            (including, without limitation, viruses, loss of data or
            compatibility conflicts).
          </p>
        </div>
        <div>
          <h5>Changes to these terms</h5>
          <p>
            We may update our Terms of use periodically. You are advised to
            review these Terms of Service regularly for any changes. Changes to
            these Terms of Service are effective when they are posted on this
            page. If the changes are significant, we may provide more prominent
            notice or get your consent as required by law. We will not change
            this agreement to allow the collection of your Data or make other
            significant changes without your consent.
          </p>
        </div>
        <div>
          <h5>Tootle Subscription</h5>
          <p>
            This section only applies when you purchase and/or subscribe to
            Tootle or other paid products. By paying the subscription fee, you
            get access to Tootle Features during the time your subscription is
            valid, subject to these Terms
          </p>
          <p>All subscriptions with Tootle are paid in advance.</p>
          <p>
            You may at any time terminate your membership, in which case your
            membership will still be valid for the subscription time you have
            already paid for. If you have subscribed to Tootle through the use
            of App Store using in-app purchase, you can only cancel your
            subscription through the use of their services. Subscription fees
            can be found in the Application. We reserve the right to change the
            subscription fees from time to time.
          </p>
          <p>
            All payments handled by Apple, such as in-app purchases, can only be
            refunded by Apple and with Apple’s consent. Default in payment shall
            not be deemed as a termination of a Tootle subscription.
          </p>
        </div>
      </div>
    </div>
  );
};

export default TermsAndCondition;

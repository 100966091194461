import React from "react";
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import {
  EmailShareButton,
  TwitterShareButton,
  WhatsappShareButton,
} from "react-share";
import { EmailIcon, TwitterIcon, WhatsappIcon } from "react-share";
const ShareMedia = (props) => {
  const {
    showShareMediaModal,
    shareMediaUrl,
    caption,
    closeShareMediaModal,
  } = props;
 
  const updateCaption = caption ?"caption:"+ caption + "\n\n" : "";
  console.log("updateCaption",updateCaption)
  const emailBody = updateCaption + shareMediaUrl;
  console.log(emailBody)
  return (
    <div>
      <Modal isOpen={showShareMediaModal}>
        <ModalHeader toggle={closeShareMediaModal}>
          <p>Share Note</p>
        </ModalHeader>
        <ModalBody>
          <WhatsappShareButton
            title={caption+"\n"}
            url={shareMediaUrl}
            windowWidth={1000}
            windowHeight={650}
          >
            <WhatsappIcon size="30" round={true}></WhatsappIcon>
          </WhatsappShareButton>
          <EmailShareButton
            body={emailBody}
            url={"https://tootle.ai"}
            windowWidth={1000}
            windowHeight={650}
          >
            <EmailIcon size="30" round={true} />
          </EmailShareButton>
          <TwitterShareButton
            title={caption+"\n"}
            url={shareMediaUrl}
            windowWidth={1000}
            windowHeight={650}
          >
            <TwitterIcon size="30" round={true} />
          </TwitterShareButton>
        </ModalBody>
        <ModalFooter>
          <Button
            onClick={closeShareMediaModal}
            className="custom-btn"
            size="sm"
          >
            Cancel
          </Button>
        </ModalFooter>
      </Modal>
    </div>
  );
};
export default ShareMedia;

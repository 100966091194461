import React from "react";
import {
  Button,
  Label,
  FormGroup,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from "reactstrap";
const DeleteMemory = (props) => {
  const {
    showDeleteMemoryModal,
    deletedMemoryType,
    deleteLoader,
    handleDeleteMemory,
    closeDeleteMemoryModal,
  } = props;
 
  return (
    <div>
      <Modal isOpen={showDeleteMemoryModal}>
        <ModalHeader toggle={closeDeleteMemoryModal}>
          <h5 className="modal-title f-w-600" id="exampleModalLabel2">
            Confirmation
          </h5>
        </ModalHeader>
        <ModalBody>
          <FormGroup className="form-group mb-3 row">
            <Label className="col-xl-12 col-sm-12 mb-0">
              {deletedMemoryType === "memory"
                ? "Are you sure you want to delete this Note ?"
                : "Are you sure you want to delete this List ?"}
            </Label>
          </FormGroup>
        </ModalBody>
        <ModalFooter>
          <Button
            type="submit"
            className="custom-btn-fill"
            size="sm"
            onClick={()=>handleDeleteMemory()}
            disabled={deleteLoader}
          >
            {deleteLoader ? "Deleting" : "Delete"}
          </Button>
          <Button onClick={closeDeleteMemoryModal} className="custom-btn" size="sm">
            Close
          </Button>
        </ModalFooter>
      </Modal>
    </div>
  );
};

export default DeleteMemory;

import React from "react";
import {
  Button,
  Label,
  FormGroup,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from "reactstrap";
const DeleteMedia = (props) => {
  const {
    showDeleteMediaModal,
    closeDeleteMediaModal,
    mediaId,
    deletMediaLoder,
    handleDeleteMedia,
    disableButton,
  } = props;

  const onSubmit = () => {
    handleDeleteMedia(mediaId);
  };
  
  return (
    <div>
      <Modal isOpen={showDeleteMediaModal}>
        <ModalHeader toggle={closeDeleteMediaModal}>
          <h5 className="modal-title f-w-600" id="exampleModalLabel2">
            Confirmation
          </h5>
        </ModalHeader>
        <ModalBody>
          <FormGroup className="form-group mb-3 row">
            <Label className="col-xl-12 col-sm-12 mb-0">
              Are you sure you want to delete this Media?
            </Label>
          </FormGroup>
        </ModalBody>
        <ModalFooter>
          <Button
            type="submit"
            className="custom-btn-fill"
            size="sm"
            onClick={onSubmit}
            disabled={disableButton}
          >
            {deletMediaLoder ? "Deleting.." : "Delete"}
          </Button>
          <Button
            className="custom-btn"
            size="sm"
            onClick={closeDeleteMediaModal}
          >
            Closed
          </Button>
        </ModalFooter>
      </Modal>
    </div>
  );
};

export default DeleteMedia;

import React, { useEffect, useRef } from "react";
import { toast } from "react-toastify";
import { addReminder, updateReminder } from "../service/ReminderService";
import moment from "moment";
import {
  setReminderTime,
  setReminderId,
  setReminderRepeatType,
} from "../redux";
import { connect } from "react-redux";

const ReminderDropDown = (props) => {
  const {
    getMemoryDetail,
    handleShowReminderDropDown,
    handleShowPickTimeAndDate,
  } = props;

  /* const "defaultTimes store default time" */
  const defaultTimes = [
    {
      id: "later_today",
      timeperiod: "Today Evening",
      timeLabel: "06:00 PM",
      time: "20:00",
    },
    {
      id: "tomorrow",
      timeperiod: "Tomorrow Morning",
      timeLabel: "08:00 AM",
      time: "8:00",
    },
    {
      id: "next_week",
      timeperiod: "Next Week",
      timeLabel: moment(new Date()).format("ddd") + ", 8:00 AM",
      time: "08:00",
    },
  ];

  const wrapperRef = useRef(null);
  useOutsideAlerter(wrapperRef);

  /**
   * this function will closed reminder dropdown when user click out side of drop down
   * @param {object} ref
   */
  function useOutsideAlerter(ref) {
    useEffect(() => {
      /**
       * Alert if clicked on outside of element
       */
      function handleClickOutside(event) {
        if (ref.current && !ref.current.contains(event.target)) {
          handleShowReminderDropDown();
        }
      }
      // Bind the event listener
      document.addEventListener("mousedown", handleClickOutside);
      return () => {
        // Unbind the event listener on clean up
        document.removeEventListener("mousedown", handleClickOutside);
      };
    }, [ref]);
  }

  /**
   * This function will give today Date
   * @returns
   */
  const getTodayDate = () => {
    let now = new Date();
    now.setDate(now.getDate());
    now.setHours(18);
    now.setMinutes(0);
    now.setSeconds(0);
    return now;
  };

  /**
   * This function will give Tomorrow Date
   * @returns
   */
  const getTomorrowDate = () => {
    let now = new Date();
    now.setDate(now.getDate() + 1);
    now.setHours(8);
    now.setMinutes(0);
    now.setSeconds(0);
    return now;
  };

  /**
   * This function will give NextWeek Same Date
   * @returns
   */
  const getNextWeekSameDay = () => {
    let now = new Date();
    now.setDate(now.getDate() + 7);
    now.setHours(8);
    now.setMinutes(0);
    now.setSeconds(0);
    return now;
  };

  /**
   * This function will Check current time is more than 8 PM or Not
   * @returns
   */
  const CheckTimeMoreThan6PM = () => {
    let today = new Date();
    if (today.getHours() >= 18) {
      return false;
    } else {
      return true;
    }
  };

  /**
   * This function will set reminder for memory
   * @param {string} id
   */
  const setReminder = (id) => {
    let userId = localStorage.getItem("userId");
    let reminderTime = "";
    if (id === "later_today") {
      reminderTime = getTodayDate();
    }
    if (id === "tomorrow") {
      reminderTime = getTomorrowDate();
    }
    if (id === "next_week") {
      reminderTime = getNextWeekSameDay();
    }
    handleShowReminderDropDown();
    props.setReminderTime(reminderTime);

    if (props.reminderTime === "" && props.reminderId === "") {
      const dataObj = {
        memoryId: props.memoryId,
        reminderTime: reminderTime,
        repeatType: "once",
        userId: userId,
      };
      addReminder(dataObj)
        .then((response) => {
          if (response.status === 200) {
            getMemoryDetail();
          } else {
            toast.error("Some Thing went wrong", {
              className: "toast-container_OTP",
              position: "top-right",
              autoClose: 3000,
            });
            getMemoryDetail();
          }
        })
        .catch((error) => {
          getMemoryDetail();
          toast.error("Some Thing went wrong", {
            className: "toast-container_OTP",
            position: "top-right",
            autoClose: 3000,
          });
        });
    } else {
      const dataObj = {
        userId: userId,
        reminderId: props.reminderId,
        memoryId: props.memoryId,
        reminderTime: reminderTime,
        repeatType: "once",
      };
      updateReminder(dataObj)
        .then((response) => {
          if (response.status === 200) {
            getMemoryDetail();
          } else {
            getMemoryDetail();
          }
        })
        .catch((error) => {
          getMemoryDetail();
          toast.error("Some Thing went wrong", {
            className: "toast-container_OTP",
            position: "top-right",
            autoClose: 3000,
          });
        });
    }
  };

  return (
    <div className="d-flex flex-column" ref={wrapperRef}>
      <label className="my-2 time_font_size_16">Reminder :</label>
      {defaultTimes.map((defaultTime, index) => {
        return (
          <div
            key={index}
            onClick={() => setReminder(defaultTime.id)}
            className={
              defaultTime.id !== "later_today" || CheckTimeMoreThan6PM()
                ? "reminder_drop_down_item"
                : "reminder_drop_down_item_disable"
            }
          >
            <div>{defaultTime.timeperiod}</div>
            <div>{defaultTime.timeLabel}</div>
          </div>
        );
      })}

      <div
        onClick={handleShowPickTimeAndDate}
        className="my-2 time_font_size_14"
      >
        <p>Pick date and time</p>
      </div>
    </div>
  );
};
const mapStateToProps = (state) => {
  return {
    memoryId: state.history?.memoryId,
    memoryName: state.history?.memoryName,
    reminderTime: state.history?.reminderTime,
    reminderId: state.history?.reminderId,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    setReminderTime: (reminderTime) => dispatch(setReminderTime(reminderTime)),
    setReminderId: (reminderId) => dispatch(setReminderId(reminderId)),
    setReminderRepeatType: (reminderRepeatType) =>
      dispatch(setReminderRepeatType(reminderRepeatType)),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(ReminderDropDown);

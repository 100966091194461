import React from "react";
import {
  Button,
  Label,
  FormGroup,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from "reactstrap";
const DeleteItemImage = (props) => {
  const {
    showdeletedItemImageModal,
    deletedItemImageId,
    handleDeleteItemImage,
    deleteLoader,
    disableBtn,
    closedeletedItemImageModal,
  } = props;

  const onSubmit = () => {
    handleDeleteItemImage(deletedItemImageId);
  };

  return (
    <div>
      <Modal isOpen={showdeletedItemImageModal}>
        <ModalHeader toggle={closedeletedItemImageModal}>
          <h5 className="modal-title f-w-600" id="exampleModalLabel2">
            Confirmation
          </h5>
        </ModalHeader>
        <ModalBody>
          <FormGroup className="form-group mb-3 row">
            <Label className="col-xl-12 col-sm-12 mb-0">
              Are you sure you want to delete this Image?
            </Label>
          </FormGroup>
        </ModalBody>
        <ModalFooter>
          <Button
            type="submit"
            className="custom-btn-fill"
            size="sm"
            onClick={onSubmit}
            disabled={disableBtn}
          >
            {deleteLoader ? "Deleting.." : "Delete"}
          </Button>
          <Button
            className="custom-btn"
            size="sm"
            onClick={closedeletedItemImageModal}
          >
            Closed
          </Button>
        </ModalFooter>
      </Modal>
    </div>
  );
};
export default DeleteItemImage;

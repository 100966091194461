import Api from "../Api"
export const shareMemory = (dataObj)=>{
  return Api.post("share",dataObj)
}

export const deleteShareMemory = (userId,memoryId,countryCode,phoneNumber)=>{
  console.log("countryCode",countryCode)
   return Api.delete(`share?user_id=${userId}&memory_id=${memoryId}&country_code=${countryCode}&phone_number=${phoneNumber}`)
    .then((res) => res.data)
    .catch((error) => error);
 
}
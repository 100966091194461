import React from 'react';
const OpenApp = (props)=>{
   return(
       <div className='open-app'>
         <i className="fas fa-mobile-alt" size={20}></i>
         <p className="open-app-text">Make lists and notes faster with App</p>
         <button onClick={()=>props.openInAppToast()}>
             Open App
         </button>
       </div>
   )
}
export default OpenApp
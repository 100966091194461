import React, { useEffect, useState, useRef } from "react";
import DatePicker from "react-datepicker";
import TimePicker from "react-time-picker";
import {
  addReminder,
  updateReminder,
  deleteReminder,
} from "../service/ReminderService";
import {
  setReminderTime,
  setReminderId,
  setReminderRepeatType,
  setcustomRepeatDuration,
  setrepeatOccurence,
  setcustomReminderUnit,
  setcustomReminderEndDateType,
  fetchReminderHistory,
} from "../redux";
import { connect } from "react-redux";
import { toast } from "react-toastify";
import Select from "react-select";
import CustomReminder from "./CustomReminder";
import DetailHeader from "../history/DetailHeader";
import DeleteReminderModal from "./DeleteReminderModal";
const TimeAndDatePicker = (props) => {
  const {
    getMemoryDetail,
    handleShowReminderDropDown,
    handleShowPickTimeAndDate,
    showDeleteReminderButton,
    setshowDeleteReminderModal,
  } = props;

  const repeatTypesOptions = [
    {
      value: "once",
      label: "Does not repeat",
    },
    {
      value: "daily",
      label: "Daily",
    },
    {
      value: "weekly",
      label: "Weekly",
    },
    {
      value: "monthly",
      label: "Monthly",
    },
    {
      value: "yearly",
      label: "Yearly",
    },
    // {
    //   value: "custom",
    //   label: "Custom",
    // },
  ];

  const styles = {
    control: (base) => ({
      ...base,
      fontSize: "14px",
      border: 0,
      height: 35,
      minHeight: 35,

      // This line disable the blue border
      boxShadow: "none",
    }),
    menu: (base) => ({
      ...base,
      fontSize: "14px",
    }),
  };

  /* const "date" store selected date from date input  */
  const [selectedDate, setselectedDate] = useState(new Date());

  /* const "time" store selected time from time input  */
  const [selectedTime, setselectedTime] = useState("10:00");

  /* const "disableSaveReminder" disable and enable the save button of time */
  const [disableSaveReminder, setdisableSaveReminder] = useState(false);

  /* const "notifyType" store notification type its date type or location type */
  const [notifyType, setnotifyType] = useState("d");

  /* const "selectedRepeatType" store repeat type from select */
  const [selectedRepeatType, setselectedRepeatType] = useState();

  /* const "showCustomReminder" show and hide custom reminder */
  const [showCustomReminder, setshowCustomReminder] = useState(false);

  /*const "repeatType" store repeat type of reminder like yearly,monthly,custom  */
  const [repeatType, setrepeatType] = useState("once");

  /* const "repeatOccurence" store repeat occurence of reminder */
  const [repeatOccurence, setrepeatOccurence] = useState("");

  /* const "customRepeatDuration" custome repeat duration */
  const [customRepeatDuration, setcustomRepeatDuration] = useState("");

  /* const "customReminderUnit" store unit of custom reminder like month,year or day */
  const [customReminderUnit, setcustomReminderUnit] = useState("");

  useEffect(() => {
    if (props.reminderTime) {
      const today = new Date();
      const date = new Date(props.reminderTime);
      if (today.getTime() < date.getTime()) {
        setdisableSaveReminder(false);
      } else {
        setdisableSaveReminder(true);
      }
      setselectedDate(date);
      setselectedTime(
        date.getHours().toString() + ":" + date.getMinutes().toString()
      );
      if (props.reminderRepeatType === "once") {
        setselectedRepeatType({
          value: "once",
          label: "Does not repeat",
        });
      }
      if (props.reminderRepeatType === "daily") {
        setselectedRepeatType({
          value: "daily",
          label: "Daily",
        });
      }
      if (props.reminderRepeatType === "weekly") {
        setselectedRepeatType({
          value: "weekly",
          label: "Weekly",
        });
      }
      if (props.reminderRepeatType === "monthly") {
        setselectedRepeatType({
          value: "monthly",
          label: "Monthly",
        });
      }
      if (props.reminderRepeatType === "yearly") {
        setselectedRepeatType({
          value: "yearly",
          label: "Yearly",
        });
      }
      if (props.reminderRepeatType === "custom") {
        setselectedRepeatType({
          value: "custom",
          label: customLabel(),
        });
      }
    } else {
      let now = new Date();
      now.setDate(now.getDate() + 1);
      setselectedDate(now);
      now.setHours(8);
      now.setMinutes(0);
      now.setSeconds(0);
      setselectedTime(
        now.getHours().toString() + ":" + now.getMinutes().toString()
      );
      setselectedRepeatType({
        value: "once",
        label: "Does not repeat",
      });
    }
  }, [props.reminderTime, props.reminderRepeatType]);

  useEffect(() => {
    const today = new Date();
    const newSelectedDate = selectedDate;
    const selectedTimeCopy = selectedTime.split(":");
    const selectedHour = selectedTimeCopy[0];
    const selectedMinit = selectedTimeCopy[1];
    newSelectedDate.setHours(selectedHour);
    newSelectedDate.setMinutes(selectedMinit);
    if (today.getTime() < newSelectedDate.getTime()) {
      setdisableSaveReminder(false);
    } else {
      setdisableSaveReminder(true);
    }
  }, [selectedDate]);

  const wrapperRef = useRef(null);

  useOutsideAlerter(wrapperRef);

  function useOutsideAlerter(ref) {
    useEffect(() => {
      /**
       * Alert if clicked on outside of element
       */
      function handleClickOutside(event) {
        if (ref.current && !ref.current.contains(event.target)) {
          handleShowReminderDropDown();
        }
      }
      // Bind the event listener
      document.addEventListener("mousedown", handleClickOutside);
      return () => {
        // Unbind the event listener on clean up
        document.removeEventListener("mousedown", handleClickOutside);
      };
    }, [ref]);
  }


  const customLabel = () => {
    if (props.customRepeatDuration === 1) {
      let status = `Repeats ${props.customReminderUnit}ly`;
      if (props.customReminderEndDateType === "Occurrences") {
        status = status + `. occur ${props.repeatOccurence} times`;
      }
      return status;
    } else {
      let status = `Repeats every ${props.customRepeatDuration} ${props.customReminderUnit}s `;
      if (props.customReminderEndDateType === "Occurrences") {
        status = status + `.  occur ${props.repeatOccurence} times`;
      }
      return status;
    }
  };

  /**
   * This function handle time change on time input
   * @param {time} time
   */
  const onChangeTimePicker = (selectedTime) => {
    setdisableSaveReminder(false);
    setselectedTime(selectedTime);
  };

  /**
   * This function will handle section repeat type value
   * @param {object} selectedOption
   */
  const handleRepeatTypes = (selectedOption) => {
    setselectedRepeatType(selectedOption);
    setrepeatType(selectedOption.value);
    if (selectedOption.value === "custom") {
      setshowCustomReminder(true);
    }
  };

  /**
   * This function will capitalize the first lettar
   * @param {string} string
   * @returns
   */
  function capitalizeFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
  }

  /**
   * This function will set reminder
   */
  const setReminder = () => {
    let userId = localStorage.getItem("userId");
    if (selectedTime) {
      const today = new Date();
      const selectedTimeCopy = selectedTime.split(":");
      const selectedHour = selectedTimeCopy[0];
      const selectedMinit = selectedTimeCopy[1];
      selectedDate.setHours(selectedHour);
      selectedDate.setMinutes(selectedMinit);

      if (today.getTime() < selectedDate.getTime()) {
        let reminderTime = new Date(selectedDate).toISOString();
        props.setReminderTime(reminderTime);
        handleShowReminderDropDown();
        if (props.reminderTime === "" && props.reminderId === "") {
          const dataObj = {
            userId: userId,
            memoryId: props.memoryId,
            reminderTime: reminderTime,
            notifyType: notifyType,
            repeatType: repeatType,
            repeatOccurence: repeatOccurence
              ? parseInt(repeatOccurence)
              : repeatOccurence,
            customRepeatDuration: customRepeatDuration
              ? parseInt(customRepeatDuration)
              : customRepeatDuration,
            customReminderUnit: customReminderUnit,
          };
          addReminder(dataObj)
            .then((response) => {
              if (response.status === 200) {
                getMemoryDetail();
              } else {
                getMemoryDetail();
              }
            })
            .catch((error) => {
              getMemoryDetail();
              toast.error("Some Thing went wrong", {
                className: "toast-container_OTP",
                position: "top-right",
                autoClose: 3000,
              });
            });
        } else {
          const dataObj = {
            userId: userId,
            reminderId: props.reminderId,
            memoryId: props.memoryId,
            reminderTime: reminderTime,
            notifyType: notifyType,
            repeatType: repeatType,
            repeatOccurence: repeatOccurence
              ? parseInt(repeatOccurence)
              : repeatOccurence,
            customRepeatDuration: customRepeatDuration
              ? parseInt(customRepeatDuration)
              : customRepeatDuration,
            customReminderUnit: customReminderUnit,
          };
          updateReminder(dataObj)
            .then((response) => {
              if (response.status === 200) {
                getMemoryDetail();
                props.fetchReminderHistory();
              } else {
                getMemoryDetail();
              }
            })
            .catch((error) => {
              getMemoryDetail();
              toast.error("Some Thing went wrong", {
                className: "toast-container_OTP",
                position: "top-right",
                autoClose: 3000,
              });
            });
        }
        setdisableSaveReminder(false);
      } else {
        setdisableSaveReminder(true);
      }
    }
  };

  /**
   * This Function will delete reminder
   */
  // const handleDeleteRemider = () => {
  //   setdeleteLoader(true);
  //   deleteReminder(props.reminderId)
  //     .then((response) => {
  //       setdeleteLoader(false);
  //       if (response.status === 200) {
  //         handleShowReminderDropDown();
  //         getMemoryDetail();
  //       } else {
  //         toast.error("Some Thing went wrong", {
  //           className: "toast-container_OTP",
  //           position: "top-right",
  //           autoClose: 5000,
  //         });
  //       }
  //     })
  //     .catch((error) => {
  //       toast.error("Some Thing went wrong", {
  //         className: "toast-container_OTP",
  //         position: "top-right",
  //         autoClose: 5000,
  //       });
  //     });
  // };

/**
 * This function will handle custom reminder
 * @param {number} repeatCount 
 * @param {stirng} selectedTimePeriod 
 * @param {stirng} selectedEndDateOption 
 * @param {number} occurrencesCount 
 */
  const handleCustomReminder = (
    repeatCount,
    selectedTimePeriod,
    selectedEndDateOption,
    occurrencesCount
  ) => {
    setcustomRepeatDuration(repeatCount);
    setcustomReminderUnit(selectedTimePeriod.label?.toLowerCase());
    setrepeatOccurence(occurrencesCount);
    if (repeatCount === "1") {
      let status = `Repeats ${selectedTimePeriod.value}`;
      if (selectedEndDateOption === "Occurrences") {
        status = status + `. occur ${occurrencesCount} times`;
      }
      setselectedRepeatType({
        label: status,
        value: "custom",
      });
    } else {
      let status = `Repeats every ${repeatCount} ${selectedTimePeriod.label?.toLowerCase()}s `;
      if (selectedEndDateOption === "Occurrences") {
        status = status + `.  occur ${occurrencesCount} times`;
      }
      setselectedRepeatType({
        label: status,
        value: "custom",
      });
    }
  };

  const customStyles = {
    control: (base) => ({
      ...base,
      border: 0,
      height: 35,
      minHeight: 35,

      // This line disable the blue border
      boxShadow: "none",
    }),
  };

  return (
    <div ref={wrapperRef}>
      {showCustomReminder ? (
        <div>
          <CustomReminder
            setshowCustomReminder={() => setshowCustomReminder(false)}
            handleCustomReminder={handleCustomReminder}
          />
        </div>
      ) : (
        <div>
          <div style={{ marginBottom: "10px" }}>
            <span onClick={handleShowPickTimeAndDate}>
              <i className="fas fa-arrow-left"></i>
            </span>

            <label
              className="my-2 time_font_size_16"
              style={{ paddingLeft: "10px" }}
            >
              Pick date and time
            </label>
          </div>
          <div style={{ borderBottom: "2px solid gray", marginBottom: "20px" }}>
            <DatePicker
              selected={selectedDate}
              minDate={new Date()}
              dateFormat="MMM d, yyyy"
              onChange={(date) => setselectedDate(date)}
            />
          </div>
          <div
            style={{
              borderBottom: disableSaveReminder
                ? "2px solid red"
                : "2px solid gray",
              marginBottom: "10px",
            }}
          >
            <TimePicker
              onChange={onChangeTimePicker}
              value={selectedTime}
              disableClock={true}
              format="h:mm a"
              hourPlaceholder="hh"
              clearAriaLabel="Clear value"
              clearIcon={null}
              minutePlaceholder="mm"
            />
          </div>
          <p style={{ color: "red", fontSize: "12px" }}>
            {disableSaveReminder && "Time has passed"}
          </p>
          <div
            className="d-flex flex-row-reverse"
            style={{ marginTop: "10px" }}
          >
            <button
              className="save_time_button"
              disabled={disableSaveReminder}
              onClick={setReminder}
            >
              Save
            </button>
            {props.reminderTime && (
              <button
                className="save_time_button"
                onClick={() => setshowDeleteReminderModal(true)}
              >
                Delete
              </button>
            )}
          </div>
        </div>
      )}
    </div>
  );
};
const mapStateToProps = (state) => {
  return {
    memoryId: state.history?.memoryId,
    memoryName: state.history?.memoryName,
    reminderTime: state.history?.reminderTime,
    reminderId: state.history?.reminderId,
    reminderRepeatType: state.history?.reminderRepeatType,
    customRepeatDuration: state.history?.customRepeatDuration,
    repeatOccurence: state.history?.repeatOccurence,
    customReminderUnit: state.history?.customReminderUnit,
    customReminderEndDateType: state.history?.customReminderEndDateType,
    reminderHistoryData: state.history?.reminderHistoryData,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    setReminderTime: (reminderTime) => dispatch(setReminderTime(reminderTime)),
    setReminderId: (reminderId) => dispatch(setReminderId(reminderId)),
    setReminderRepeatType: (reminderRepeatType) =>
      dispatch(setReminderRepeatType(reminderRepeatType)),
    setcustomRepeatDuration: (customRepeatDuration) =>
      dispatch(setcustomRepeatDuration(customRepeatDuration)),
    setrepeatOccurence: (repeatOccurence) =>
      dispatch(setrepeatOccurence(repeatOccurence)),
    setcustomReminderUnit: (customReminderUnit) =>
      dispatch(setcustomReminderUnit(customReminderUnit)),
    setcustomReminderEndDateType: (customReminderEndDateType) =>
      dispatch(setcustomReminderEndDateType(customReminderEndDateType)),
    fetchReminderHistory: (reminderHistoryData) =>
      dispatch(fetchReminderHistory(reminderHistoryData)),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(TimeAndDatePicker);

import React from "react";
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import {
  EmailShareButton,
  TwitterShareButton,
  WhatsappShareButton,
} from "react-share";
import { EmailIcon, TwitterIcon, WhatsappIcon } from "react-share";
const ShareAllMedia = (props) => {
  const {
    showAllshareFileModal,
    defaultAttachmentUrl,
    allAttachmentUrl,
    closeAllshareFileModal,
  } = props;

  return (
    <div>
      <Modal isOpen={showAllshareFileModal}>
        <ModalHeader toggle={closeAllshareFileModal}>
          <p>Share Note</p>
        </ModalHeader>
        <ModalBody>
          <WhatsappShareButton
            title={allAttachmentUrl}
            url={defaultAttachmentUrl}
            windowWidth={1000}
            windowHeight={650}
          >
            <WhatsappIcon size="30" round={true}></WhatsappIcon>
          </WhatsappShareButton>
          <EmailShareButton
            body={allAttachmentUrl}
            url={defaultAttachmentUrl}
            windowWidth={1000}
            windowHeight={650}
          >
            <EmailIcon size="30" round={true} />
          </EmailShareButton>
          <TwitterShareButton
            title={allAttachmentUrl}
            url={defaultAttachmentUrl}
            windowWidth={1000}
            windowHeight={650}
          >
            <TwitterIcon size="30" round={true} />
          </TwitterShareButton>
        </ModalBody>
        <ModalFooter>
          <Button
            onClick={closeAllshareFileModal}
            className="custom-btn"
            size="sm"
          >
            Cancel
          </Button>
        </ModalFooter>
      </Modal>
    </div>
  );
};

export default ShareAllMedia;

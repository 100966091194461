import React, { useState } from "react";

const Caption = (props) => {
  const [readMore, setReadMore] = useState(true);
  console.log("props.Url.caption.length", props.Url.caption.length > 100);
  console.log("readMore", readMore);
  return (
    <div
      className="align-self-start"
      style={{
        background: "#e2e2e2",
        padding: "5px",
      }}
    >
      {props.Url.caption.length > 150 && readMore ? (
        <div>
          <p className="text-truncate">{props.Url.caption}</p>
          <p onClick={()=>setReadMore(false)} className="read_more_text">
            read more
          </p>
        </div>
      ) : (
        <p style={{wordBreak:"break-all",whiteSpace:'pre-wrap'}}>{props.Url.caption}</p>
      )}
    </div>
  );
};

export default Caption;

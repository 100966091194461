import React, { useState, useEffect } from "react";
import Webcam from "react-webcam";
import { Button, Modal, ModalBody, ModalFooter } from "reactstrap";
const UploadCameraImage = (props) => {
  const {
    showCamera,
    closeCamera,
    opneEditCameraImageModal,
    disableButton,
  } = props;

  const webcamRef = React.useRef(null);

  const [imgSrc, setImgSrc] = React.useState(null);

  const [cameraAllow, setCameraAllow] = useState(false);

  const [visible, setVisible] = React.useState(true);

  useEffect(() => {
    navigator.mediaDevices
      .getUserMedia({ video: true })
      .then(successCallback, errorCallback);

    if (imgSrc) {
      opneEditCameraImageModal(imgSrc);
    }
  }, [imgSrc]);

  var successCallback = function (success) {
    setCameraAllow(true);
  };

  var errorCallback = function (error) {
    if (error.name == "NotAllowedError") {
      setCameraAllow(false);
      closeCamera();
    }
  };

  const capture = React.useCallback(() => {
    const imageSrc = webcamRef.current.getScreenshot();
    setImgSrc(imageSrc);
  }, [webcamRef, setImgSrc]);

  /**
  * This function will close camera
  */
  const handleClosedCamera = () => {
    setVisible(false);
    closeCamera();
  };


  return (
    <div>
      <Modal
        isOpen={showCamera && cameraAllow}
        size="lg"
        style={{ maxWidth: "700px", width: "96.5%" }}
      >
        <ModalBody style={{ width: "675px" }}>
          {visible && (
            <Webcam
              audio={false}
              ref={webcamRef}
              screenshotFormat="image/jpeg"
            />
          )}
        </ModalBody>
        <ModalFooter>
          <Button
            onClick={capture}
            disabled={disableButton}
            className="custom-btn-fill"
            size="sm"
          >
            {disableButton ? "Uploading..." : "Upload"}
          </Button>
          <Button onClick={handleClosedCamera} className="custom-btn" size="sm">
            Cancel
          </Button>
        </ModalFooter>
      </Modal>
    </div>
  );
};

export default UploadCameraImage;

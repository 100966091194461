import React, { useState, useEffect } from "react";
import { Button } from "reactstrap";
import logo from "../assets/images/NewLogo.png";
import LogoutConfirm from "../auth/LogoutConfirm";
import { authentication } from "../firebase/firebase";
import { useNavigate } from "react-router-dom";
import { hidehistoryDetailOnMobile } from "../redux";
import { connect } from "react-redux";
import { deviceType } from "../comman/Common";
import { useParams, useLocation } from "react-router-dom";
const ReminderHeader = (props) => {
  const { showallHistoryOnMobile, setshowallHistoryOnMobile } = props;

  const navigate = useNavigate();

  const [showLogoutConfirm, setshowLogoutConfirm] = useState(false);

  let params = useParams();

  const memoryId = params.memoryId;

  /**
   * open Logout confirmation
   */
  const openLogOutConfirm = () => {
    setshowLogoutConfirm(true);
  };

  /**
   * Logout the user
   */
  const Logout = () => {
    localStorage.removeItem("userId");
    localStorage.clear();
    authentication.signOut();
    navigate("/");
  };

  return (
    <div>
      <div
        className="d-flex flex-row align-items-center history-header"
        style={{ boxShadow: "0 2px 5px #e5e5e5" }}
      >
        <div style={{ flex: 1 }}>
          <img src={logo} alt="image" width="130" />
        </div>

        <Button onClick={openLogOutConfirm} className="custom-btn" size="sm">
          <i className="fas fa-sign-out-alt"></i>
        </Button>
      </div>

      {showLogoutConfirm && (
        <LogoutConfirm
          showLogoutConfirm={showLogoutConfirm}
          closeLogoutConfirm={() => setshowLogoutConfirm(false)}
          Logout={Logout}
        />
      )}
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    showhistoryDetailpage: state.history?.showDetailpage,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    hidehistoryDetailOnMobile: () => dispatch(hidehistoryDetailOnMobile()),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(ReminderHeader);

import React, { useState,useEffect } from "react";
import { DropdownToggle, DropdownMenu, Dropdown } from "reactstrap";
import ReminderDropDown from "../reminder/ReminderDropDown";
import TimeAndDatePicker from "../reminder/TimeAndDatePicker";
import { connect } from "react-redux";
import { deviceType } from "../comman/Common";
import {
  fetchUsersAllMemory,
  setReminderTime,
  setReminderId,
  hidehistoryDetailOnMobile,
  setReminderRepeatType,
  setcustomRepeatDuration,
  setrepeatOccurence,
  setcustomReminderUnit,
  setcustomReminderEndDateType,
  setMemoryId,
  setMemoryName,
  setReminderMonth
} from "../redux";

const ReminderToggle = (props) => {
  
  const { memoryId, reminder, monthYear,handleMarkAsDone } = props;

  const [dropdownOpen, setdropdownOpen] = useState(false);

  const [showReminderDropDown, setshowReminderDropDown] = useState(false);

  const [showPickDateAndTime, setshowPickDateAndTime] = useState(false);

  /**
   * This function will Update MemoryDetail
   */
  const updateMemortDetail = () => {
    props.setMemoryId(memoryId);
    props.setReminderTime(reminder.reminder_time);
    props.setReminderId(reminder.reminder_id);
    props.setReminderRepeatType(reminder.repeat_type);
    props.setcustomRepeatDuration(reminder.custom_repeat_duration);
    props.setrepeatOccurence(reminder.repeat_occurrence);
    props.setcustomReminderUnit(reminder.custom_reminder_unit);
    props.setcustomReminderEndDateType(
      reminder.repeat_occurrence ? "Occurrences" : "Never"
    );
  };

  /**
   * This function is reponsible for showing and hideing reminder dropdown
   */
  const handleShowReminderDropDown = () => {
    setshowReminderDropDown(!showReminderDropDown);
  };

  /**
   * This function will show and hide "time and date pick" screen on reminder dropdwon
   */
  const handleShowPickTimeAndDate = () => {
    setshowPickDateAndTime(!showPickDateAndTime);
  };

  const openEditReminder = (monthYear) => {
    props.setReminderMonth(monthYear)
    updateMemortDetail();
    setshowReminderDropDown(true);
    setdropdownOpen(false);
  };

  const markAsDone = () => {
    handleMarkAsDone();
    setdropdownOpen(!dropdownOpen);
  };

  const handleToggle = () => {
    updateMemortDetail();
    setdropdownOpen(!dropdownOpen);
  };

  return (
    <div style={{ position: "relative" }}>
      <Dropdown isOpen={dropdownOpen} toggle={handleToggle}>
        <DropdownToggle aria-expanded data-toggle="dropdown" tag="span">
          <div>
            <i style={{ padding: "0 10px",cursor:"pointer" }} className="fas fa-ellipsis-v"></i>
          </div>
        </DropdownToggle>
        <DropdownMenu>
          <div>
            {/* <div onClick={()=>openEditReminder(monthYear)} style={{ margin: "5px 0px" }}>
              Edit
            </div> */}
            <div onClick={markAsDone} style={{ margin: "1px 0px" }}>
              Mark as Done
            </div>
          </div>
        </DropdownMenu>
      </Dropdown>

      {/* {showReminderDropDown && (
        <div
          className="reminder_dropdown"
          style={{
            top: 25,
            position: "absolute",
            width: deviceType !== "mobile" ? "350px" : "250px",
          }}
        >
          {showPickDateAndTime ? (
            <ReminderDropDown
              getMemoryDetail={updateMemortDetail}
              handleShowReminderDropDown={handleShowReminderDropDown}
              handleShowPickTimeAndDate={handleShowPickTimeAndDate}
            />
          ) : (
            <TimeAndDatePicker
              getMemoryDetail={updateMemortDetail}
              handleShowReminderDropDown={handleShowReminderDropDown}
              handleShowPickTimeAndDate={handleShowPickTimeAndDate}
            />
          )}
        </div>
      )} */}
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    userData: state.history?.users,
    reminderTime: state.history?.reminderTime,
    reminderId: state.history?.reminderId,
    showDetailpage: state.history?.showDetailpage,
    reminderRepeatType: state.history?.reminderRepeatType,
    reminderMonth:state.history?.reminderMonth
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    fetchUsersAllMemory: () => dispatch(fetchUsersAllMemory()),
    hidehistoryDetailOnMobile: () => dispatch(hidehistoryDetailOnMobile()),
    setReminderTime: (reminderTime) => dispatch(setReminderTime(reminderTime)),
    setReminderId: (reminderId) => dispatch(setReminderId(reminderId)),
    setReminderRepeatType: (reminderRepeatType) =>
      dispatch(setReminderRepeatType(reminderRepeatType)),
    setcustomRepeatDuration: (customRepeatDuration) =>
      dispatch(setcustomRepeatDuration(customRepeatDuration)),
    setrepeatOccurence: (repeatOccurence) =>
      dispatch(setrepeatOccurence(repeatOccurence)),
    setcustomReminderUnit: (customReminderUnit) =>
      dispatch(setcustomReminderUnit(customReminderUnit)),
    setcustomReminderEndDateType: (customReminderEndDateType) =>
      dispatch(setcustomReminderEndDateType(customReminderEndDateType)),
    setMemoryId: (memoryId) => dispatch(setMemoryId(memoryId)),
    setMemoryName: (memoryName) => dispatch(setMemoryName(memoryName)),
    setReminderMonth:(reminderMonth)=>dispatch(setReminderMonth(reminderMonth))
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(ReminderToggle);

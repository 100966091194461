import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";

const ShowMemoryStatus = (props) => {
  const { showNoRecord } = props;
  const navigate = useNavigate();
  useEffect(() => {
    navigate("/memory");
  }, []);
  if (showNoRecord === true) {
    return (
      <div className="info">
        <p>No Record Found</p>
      </div>
    );
  }
  return (
    <div className="info">
      <p>Click on + New List to create list</p>
      <p>Click on + New Note to create Note</p>
    </div>
  );
};

export default ShowMemoryStatus;

import React,{useEffect} from 'react'
import { Outlet, useNavigate } from 'react-router-dom'
import AdminHeader from './AdminHeader'
import './admin.css'
const Admin = () => {
 const navigate= useNavigate()
  useEffect(()=>{
   navigate("/admin/sidebar/notification")
  },[])
  return (
    <>
      <AdminHeader />
      <Outlet />
    </>
  )
}
export default Admin



// import { Table } from 'reactstrap';

// function Admin() {
//   return (
//     <div className="App">
//       <Table striped>
//   <thead>
//     <tr>
//       <th>
//         #
//       </th>
//       <th>
//         First Name
//       </th>
//       <th>
//         Last Name
//       </th>
//       <th>
//         Username
//       </th>
//     </tr>
//   </thead>
//   <tbody>
//     <tr>
//       <th scope="row">
//         1
//       </th>
//       <td>
//         Mark
//       </td>
//       <td>
//         Otto
//       </td>
//       <td>
//         @mdo
//       </td>
//     </tr>
//     <tr>
//       <th scope="row">
//         2
//       </th>
//       <td>
//         Jacob
//       </td>
//       <td>
//         Thornton
//       </td>
//       <td>
//         @fat
//       </td>
//     </tr>
//     <tr>
//       <th scope="row">
//         3
//       </th>
//       <td>
//         Larry
//       </td>
//       <td>
//         the Bird
//       </td>
//       <td>
//         @twitter
//       </td>
//     </tr>
//     <tr>
//       <th scope="row">
//         2
//       </th>
//       <td>
//         Jacob
//       </td>
//       <td>
//         Thornton
//       </td>
//       <td>
//         @fat
//       </td>
//     </tr>
//     <tr>
//       <th scope="row">
//         2
//       </th>
//       <td>
//         Jacob
//       </td>
//       <td>
//         Thornton
//       </td>
//       <td>
//         @fat
//       </td>
//     </tr>
//     <tr>
//       <th scope="row">
//         2
//       </th>
//       <td>
//         Jacob
//       </td>
//       <td>
//         Thornton
//       </td>
//       <td>
//         @fat
//       </td>
//     </tr>
//     <tr>
//       <th scope="row">
//         2
//       </th>
//       <td>
//         Jacob
//       </td>
//       <td>
//         Thornton
//       </td>
//       <td>
//         @fat
//       </td>
//     </tr>
//     <tr>
//       <th scope="row">
//         2
//       </th>
//       <td>
//         Jacob
//       </td>
//       <td>
//         Thornton
//       </td>
//       <td>
//         @fat
//       </td>
//     </tr>
//     <tr>
//       <th scope="row">
//         2
//       </th>
//       <td>
//         Jacob
//       </td>
//       <td>
//         Thornton
//       </td>
//       <td>
//         @fat
//       </td>
//     </tr>
//     <tr>
//       <th scope="row">
//         2
//       </th>
//       <td>
//         Jacob
//       </td>
//       <td>
//         Thornton
//       </td>
//       <td>
//         @fat
//       </td>
//     </tr>
//     <tr>
//       <th scope="row">
//         2
//       </th>
//       <td>
//         Jacob
//       </td>
//       <td>
//         Thornton
//       </td>
//       <td>
//         @fat
//       </td>
//     </tr>
//     </tbody>
// </Table>
//     </div>
//   );
// }

// export default Admin;

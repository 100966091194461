import React from "react";
import logo from "../../assets/images/NewLogo.png";
import { connect } from "react-redux";
import { setMenuToggle } from "../../redux";
const AdminHeader = (props) => {

  return (
    <div className="admin-header">
      <div onClick={() => props.setMenuToggle(!props.menuToggle)}>
        <i className="fas fa-bars" size={80}></i>
      </div>
      <div className="admin-logo">
        <img src={logo} alt="logo" />
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    menuToggle: state.history?.menuToggle,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    setMenuToggle: (menuToggle) => dispatch(setMenuToggle(menuToggle)),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(AdminHeader);

import {
  FETCH_USERS_REQUEST,
  FETCH_USERS_SUCCESS,
  FETCH_USERS_FAILURE,
  HIDE_MOBILE_HISTORY_DETAIL,
  SHOW_MOBILE_HISTORY_DETAIL,
  REMINDER_TIME,
  REMINDER_ID,
  REMINDER_REPEAT_TYPE,
  CUSTOM_REPEAT_DURATION,
  REPEAT_OCCURENCE,
  CUSTOM_REMINDER_UNIT,
  CUSTOM_REMINDER_END_DATE_TYPE,
  MEMORY_NAME,
  MEMORY_ID,
  SHOW_MEMORY_NAME_IN_EDIT_MODE,
  FETCH_REMINDER_HISTORY_REQUEST,
  FETCH_REMINDER_HISTORY_SUCCESS,
  FETCH_REMINDER_HISTORY_FAILURE,
  REMINDER_MONTH,
  CURRENT_DEVICE_TOKEN,
  SESSIONID,
  TOTAL_SHARED,
  SHARED_DATA,
  SIDE_MENU_TOGGLE
} from "./historyTypes";

const initialState = {
  loading: false,
  showDetailpage: false,
  historyNameToggle: false,
  users: [],
  error: "",
  reminderTime: "",
  reminderId: "",
  reminderRepeatType: "",
  customRepeatDuration: "",
  repeatOccurence: "",
  customReminderUnit: "",
  customReminderEndDateType: "",
  memoryId: "",
  memoryName: "",
  memoryNameInEditMode: false,
  reminderHistoryData: [],
  reminderMonth: "",
  currentDeviceToken: null,
  sessionId:"",
  totalShared:"",
  sharedData:[],
  menuToggle:false
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_USERS_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case FETCH_USERS_SUCCESS:
      return {
        ...state,
        loading: false,
        users: action.payload,
        error: "",
      };
    case FETCH_USERS_FAILURE:
      return {
        ...state,
        loading: false,
        users: [],
        error: action.payload,
      };
    case SHOW_MOBILE_HISTORY_DETAIL:
      return {
        ...state,
        showDetailpage: true,
      };
    case HIDE_MOBILE_HISTORY_DETAIL:
      return {
        ...state,
        showDetailpage: false,
      };
    case REMINDER_TIME:
      return {
        ...state,
        reminderTime: action.payload,
      };
    case REMINDER_ID:
      return {
        ...state,
        reminderId: action.payload,
      };
    case REMINDER_REPEAT_TYPE:
      return {
        ...state,
        reminderRepeatType: action.payload,
      };
    case CUSTOM_REPEAT_DURATION:
      return {
        ...state,
        customRepeatDuration: action.payload,
      };
    case REPEAT_OCCURENCE:
      return {
        ...state,
        repeatOccurence: action.payload,
      };
    case CUSTOM_REMINDER_UNIT:
      return {
        ...state,
        customReminderUnit: action.payload,
      };
    case CUSTOM_REMINDER_END_DATE_TYPE:
      return {
        ...state,
        customReminderEndDateType: action.payload,
      };
    case MEMORY_ID:
      return {
        ...state,
        memoryId: action.payload,
      };
    case MEMORY_NAME:
      return {
        ...state,
        memoryName: action.payload,
      };
    case SHOW_MEMORY_NAME_IN_EDIT_MODE:
      return {
        ...state,
        memoryNameInEditMode: action.payload,
      };
    case FETCH_REMINDER_HISTORY_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case FETCH_REMINDER_HISTORY_SUCCESS:
      return {
        ...state,
        loading: false,
        reminderHistoryData: action.payload,
        error: "",
      };
    case FETCH_REMINDER_HISTORY_FAILURE:
      return {
        ...state,
        loading: false,
        reminderHistoryData: [],
        error: action.payload,
      };
    case REMINDER_MONTH:
      return {
        ...state,
        reminderMonth: action.payload,
      };
    case CURRENT_DEVICE_TOKEN:
      return {
        ...state,
        currentDeviceToken: action.payload,
      };
      case SESSIONID:
      return {
        ...state,
        sessionId: action.payload,
      };
      case TOTAL_SHARED:
      return {
        ...state,
        totalShared: action.payload,
      };
      case SHARED_DATA:
      return {
        ...state,
        sharedData: action.payload,
      };
      case SIDE_MENU_TOGGLE:
        return{
          ...state,
          menuToggle:action.payload
      }
    default:
      return state;
  }
};

export default reducer;

import React, { useRef, useEffect } from "react";
import moment from "moment";
import ReminderToggle from "./ReminderToggle";
import { Link } from "react-router-dom";
import { showMemoryNameInEditMode } from "../redux";
const MonthReminder = (props) => {
  const {
    index,
    todayScrollToggle,
    settodayScrollToggle,
    reminder,
    handleMarkAsDone,
    monthReminder,
  } = props;
  const reminderRef = useRef();
  useEffect(() => {
    let date = new Date();
    const currentDay = moment(date).format("YYYY-MM-DD");
    const currentreminderDay = moment(reminder.reminder_time).format(
      "YYYY-MM-DD"
    );

    if (todayScrollToggle && currentDay === currentreminderDay) {
      reminderRef.current.scrollIntoView();
      settodayScrollToggle(false);
    }
  }, [reminder, todayScrollToggle]);
  return (
    <div ref={reminderRef}>
      <div className="history_reminder" key={index}>
        <div className="reminder_day">
          <div>{moment(reminder.reminder_time).format("DD")}</div>
          <div>{moment(reminder.reminder_time).format("ddd")}</div>
        </div>
        <div className="reminder_name">
          <Link
            to={`/memory/${reminder.memory_id}`}
            state={{
              memoryNameInEditMode: false,
              type: reminder.type,
            }}
          >
            <div className="text-truncate">
              {reminder.title ? reminder.title : "New List"}
            </div>
          </Link>
          <div className="reminder_time">
            <i
              className="far fa-clock"
              style={{
                marginRight: "3px",
                fontSize: "12px",
              }}
            ></i>
            {moment(reminder.reminder_time).format("h:mm a")}
          </div>
        </div>
        <div>
          <ReminderToggle
            memoryId={reminder.memory_id}
            reminder={reminder}
            monthYear={reminder.monthYear}
            handleMarkAsDone={() =>
              handleMarkAsDone(
                reminder.reminder_id,
                reminder.utc_time,
                monthReminder.monthYear,
                index
              )
            }
          />
        </div>
      </div>
    </div>
  );
};

export default MonthReminder;

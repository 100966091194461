import React, { useEffect, useState } from "react";
import { Routes, Route } from "react-router-dom";
import Login from "./auth/Login";
import UserMemory from "./history/UserMemory";
import { ToastContainer } from "react-toastify";
import UserMemoryDetail from "./history/UserMemoryDetail";
import DetailHeader from "./history/DetailHeader";
import { Col } from "reactstrap";
import ReminderHistory from "./reminder/ReminderHistory";
import { setCurrentDeviceToken } from "./redux";
import { requestForToken } from "./firebase/firebase";
import { connect } from "react-redux";
import { updateNotificationToken } from "./service/UserService";
import { toast } from "react-toastify";
import PrivacyPolicy from "./Pages/PrivacyPolicy";
import TermsAndCondition from "./Pages/TermsAndCondition";
import Support from "./Pages/Support";
import ThankYoupage from "./Pages/ThankYoupage";
import { useLocation } from "react-router-dom";
import AdminLogin from "./Admin/AdminLogin";
import AdminSideBar from "./Admin/AdminSideBar";
import Notification from "./Admin/Notification.js";
import Admin from "./Admin";
import OpenApp from './comman/OpenApp';
import { isAndroid, isIOS } from "react-device-detect";
const App = (props) => {

  const [currentToken, setcurrentToken] = useState(null);

  const location = useLocation();

  useEffect(() => {
    localStorage.setItem("current_url", location.pathname);
    requestForToken(setcurrentToken);
  }, []);

  useEffect(() => {
    let sessionId = localStorage.getItem("sessionId");
    if (currentToken) {
       props.setCurrentDeviceToken(currentToken);
    }
    if (sessionId) {
      const dataObj = {
        sessionId: sessionId,
        deviceToken: currentToken,
      };
      updateNotificationToken(dataObj)
        .then((response) => {
          if (response.status === 200) {
            console.log(response);
          } else {
            toast.error("Something went wrong", {
              className: "toast-container_OTP",
              position: "top-right",
              autoClose: 3000,
            });
          }
        })
        .catch((error) => {
          toast.error("Something went wrong", {
            className: "toast-container_OTP",
            position: "top-right",
            autoClose: 3000,
          });
        });
    }
  }, [currentToken]);

  
  

  const openInAppToast = () => {
    if (isAndroid) {
      return window.location.replace(
        "intent://tootle_ai.com/#Intent;scheme=https;package=com.perfectmemory;end"
      );
    } else if (isIOS) {
      return window.location.replace(
        "itms://itunes.apple.com/us/app/tootle-ai/id1585348003?mt=8"
      );
    }
  };

  return (
    <div>
      <Routes>
        <Route path="/" element={<Login />} />
        <Route path="/admin/login" element={<AdminLogin />} />
        <Route path="/TermsAndCondition" element={<TermsAndCondition />} />
        <Route path="/support/thankyou" element={<ThankYoupage />} />
        <Route path="/support" element={<Support />} />
        <Route path="/PrivacyPolicy" element={<PrivacyPolicy />} />
        <Route path="memory" element={<UserMemory />}>
          <Route
            index
            element={
              <Col lg="9" md="8" className="col-12">
              {isAndroid||isIOS?<OpenApp openInAppToast={openInAppToast}/>:null}
              <DetailHeader />
              </Col>
            }
          />
          <Route path="reminders" element={<ReminderHistory />} />
          <Route path=":memoryId" element={<UserMemoryDetail />} />
        </Route>

        <Route path="/admin" element={<Admin />}>
          <Route path="sidebar" element={<AdminSideBar />}>
            <Route path="notification" element={<Notification />} />
          </Route>
        </Route>
        <Route
          path="*"
          element={
            <main style={{ padding: "1rem" }}>
              <p>There's nothing here!</p>
            </main>
          }
        />
      </Routes>

      <ToastContainer
        toastStyle={{ backgroundColor: "#636ac6", color: "white" }}
        position="top-right"
        autoClose={3000}
        pauseOnHover={false}
      />
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    currentDeviceToken: state.history?.currentDeviceToken,
    sessionId: state.history?.sessionId,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    setCurrentDeviceToken: (currentDeviceToken) =>
      dispatch(setCurrentDeviceToken(currentDeviceToken)),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(App);

import React, { useState, useEffect } from "react";
import { Modal, ModalBody, ModalHeader, ModalFooter, Button } from "reactstrap";
import BootstrapTable from "react-bootstrap-table-next";
import { getUserList } from "../../service/AdminServices";
import paginationFactory from "react-bootstrap-table2-paginator";
const SelectUserModal = (props) => {
  const {
    showAllUserModal,
    setallSelectedUser,
    disablebtn,
    closeAllUserModal,
  } = props;

  const [selectedUserList, setselectedUserList] = useState([]);

  const [render, setrender] = useState(true);

  const [allUserList, setallUserList] = useState([]);

  const [limit, setlimit] = useState(80);

  const [offset, setoffset] = useState(0);

  const columns = [
    {
      dataField: "user_id",
      text: "User Id",
    },
    {
      dataField: "first_name",
      text: "First Name",
    },
    {
      dataField: "last_name:",
      text: "Last Name",
    },
    {
      dataField: "phone_number",
      text: "Phone Number",
    },
  ];

  useEffect(() => {
    fetchUserList(limit, offset);
  }, []);

  const fetchUserList = (limit, offset) => {
    getUserList(limit, offset)
      .then((res) => {
        if (res.status === 200) {
          const updatedData = [...allUserList, ...res.data];
          console.log("updatedData", updatedData);
          console.log(res.data);
          setallUserList(updatedData);
        }
      })
      .catch((error) => {
        console.log("error", error);
      });
  };

  const handleOnSelect = (row, isSelect) => {
    if (isSelect) {
      const updateSelectedUserList = [...selectedUserList, row];
      setselectedUserList(updateSelectedUserList);
    } else {
      selectedUserList.map((user, index) => {
        if (row.user_id === user.user_id) {
          selectedUserList.splice(index, 1);
          setrender(!render);
        }
      });
    }
  };

  const handleOnSelectAll = (isSelect, rows) => {
    console.log("rows", rows);
    if (isSelect) {
      const updatedRow = [...selectedUserList, ...rows];
      setselectedUserList(updatedRow);
    } else {
      rows.map((row, index) => {
        selectedUserList.map((user, index) => {
          if (row.user_id === user.user_id) {
            selectedUserList.splice(index, 1);
          }
        });
      });
      setrender(!render);
    }
  };

  const selectRow = {
    mode: "checkbox",
    clickToSelect: true,
    onSelect: handleOnSelect,
    onSelectAll: handleOnSelectAll,
  };

  const handleSelectUserList = () => {
    const obj = {};

    setallSelectedUser(selectedUserList);
    closeAllUserModal();
  };

  const options = {
    sizePerPage: 8,
    onSizePerPageChange: (sizePerPage, page) => {
      console.log("Size per page change!!!");
      console.log("Newest size per page:" + sizePerPage);
      console.log("Newest page:" + page);
      console.log("allUserList.length", allUserList.length);
      const count = Math.ceil(allUserList.length / sizePerPage);
      console.log("count", count);
      if (count === page) {
        fetchUserList(limit, allUserList.length);
        setrender(!render);
      }
    },
    onPageChange: (page, sizePerPage) => {
      console.log("Page change!!!");
      console.log("Newest size per page:" + sizePerPage);
      console.log("Newest page:" + page);
      console.log("allUserList.length", allUserList.length);
      const count = Math.ceil(allUserList.length / sizePerPage);
      console.log("count", count);
      if (count === page) {
        fetchUserList(limit, allUserList.length);
        setrender(!render);
      }
    },
  };

  return (
    <Modal
      isOpen={showAllUserModal}
      size="lg"
      style={{ maxWidth: "900px", width: "100%" }}
    >
      <ModalHeader toggle={closeAllUserModal}>Select User</ModalHeader>
      <ModalBody style={{ heigth: "200px" }}>
        <BootstrapTable
          keyField="user_id"
          data={allUserList}
          columns={columns}
          selectRow={selectRow}
          noDataIndication={() => "loading..."}
          pagination={paginationFactory(options)}
        />
      </ModalBody>
      <ModalFooter>
        <Button
          onClick={handleSelectUserList}
          disabled={selectedUserList.length <= 0}
          className="custom-btn-fill"
          size="sm"
        >
          {disablebtn ? "Submiting..." : "Submit"}
        </Button>
        <Button onClick={closeAllUserModal} className="custom-btn" size="sm">
          {" "}
          Close
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default SelectUserModal;

export const FETCH_USERS_REQUEST = "FETCH_USERS_REQUEST";
export const FETCH_USERS_SUCCESS = "FETCH_USERS_SUCCESS";
export const FETCH_USERS_FAILURE = "FETCH_USERS_FAILURE";
export const SHOW_MOBILE_HISTORY_DETAIL = "SHOW_MOBILE_HISTORY_DETAIL";
export const HIDE_MOBILE_HISTORY_DETAIL = "HIDE_MOBILE_HISTORY_DETAIL";
export const REMINDER_TIME = "REMINDER_TIME";
export const REMINDER_ID = "REMINDER_ID";
export const REMINDER_REPEAT_TYPE="REMINDER_REPEAT_TYPE";
export const CUSTOM_REPEAT_DURATION="CUSTOM_REPEAT_DURATION";
export const REPEAT_OCCURENCE="REPEAT_OCCURENCE"
export const CUSTOM_REMINDER_UNIT="CUSTOM_REMINDER_UNIT"
export const CUSTOM_REMINDER_END_DATE_TYPE="CUSTOM_REMINDER_END_DATE_TYPE";
export const MEMORY_ID="MEMORY_ID";
export const MEMORY_NAME="MEMORY_NAME";
export const FETCH_MEMORY_DETAIL_REQUEST = "FETCH_MEMORY_DETAIL_REQUEST";
export const FETCH_MEMORY_DETAIL_SUCCESS = "FETCH_MEMORY_DETAIL_SUCCESS";
export const FETCH_MEMORY_DETAIL_FAILURE
= " FETCH_MEMORY_DETAIL_FAILURE";
export const SHOW_MEMORY_NAME_IN_EDIT_MODE="SHOW_MEMORY_NAME_IN_EDIT_MODE"
export const FETCH_REMINDER_HISTORY_REQUEST = "FETCH_REMINDER_HISTORY_REQUEST";
export const FETCH_REMINDER_HISTORY_SUCCESS = "FETCH_REMINDER_HISTORY_SUCCESS";
export const FETCH_REMINDER_HISTORY_FAILURE
= " FETCH_REMINDER_HISTORY_FAILURE";
export const REMINDER_MONTH="REMINDER_MONTH"
export const CURRENT_DEVICE_TOKEN="CURRENT_DEVICE_TOKEN"
export const SESSIONID="SESSIONID"
export const TOTAL_SHARED="TOTAL_SHARED"
export const SHARED_DATA="SHARED_DATA"
export const SIDE_MENU_TOGGLE="SIDE_MENU_TOGGLE"
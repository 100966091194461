import React, { useState, useEffect } from "react";
import {
  Button,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Spinner,
} from "reactstrap";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Cropper from "react-cropper";
import "cropperjs/dist/cropper.css";
import TextareaAutosize from "react-textarea-autosize";
import { Line } from 'rc-progress';
const UploadImage = (props) => {
  const {
    showUploadImageModal,
    filesLoader,
    allImageFiles,
    allReadImageFilesObjects,
    onChangeImage,
    sendImageOnServer,
    disableButton,
    disablebtn,
    percentCompleted,
    closeUploadImageModal,
  } = props;

  /* cosnt "loading" store loading status */
  const [loading, setloading] = useState(true);

  /* cosnt "clickImagePath" store image path of click image */
  const [clickImagePath, setclickImagePath] = useState();

  /* const "showCroppedImage" toggle for showing cropped image  */
  const [showCroppedImage, setshowCroppedImage] = useState(false);

  /* const  "editImageIndex" store edit image index*/
  const [editImageIndex, seteditImageIndex] = useState(0);

  /*const "editImageObject" store edit image object  */
  const [editImageObject, seteditImageObject] = useState();

  /* const "cropper" store cropper of image */
  const [cropper, setCropper] = useState();

  /* const "rerender" rerender the component */
  const [rerender, setrerender] = useState(true);

  /* const "showborder" toggle for showing border*/
  const [showborder, setshowborder] = useState(true);

  /* const "captionValue" store caption value */
  const [captionValue, setcaptionValue] = useState("");

  useEffect(() => {
    if (allReadImageFilesObjects.length > 0) {
      setclickImagePath(allReadImageFilesObjects[0].path);
      seteditImageObject(allReadImageFilesObjects[0]);
      seteditImageIndex(0);
      setloading(false);
    }
  }, [allReadImageFilesObjects]);

  useEffect(() => {
    if (showborder) setshowborder(false);
  }, [showborder]);

  /**
   * This function will show click Image
   * @param {string} ImagePath
   * @param {object} ImageObject
   * @param {number} index
   */
  const showClickImage = (ImagePath, ImageObject, index) => {
    setcaptionValue(ImageObject.caption);
    setshowborder(true);
    setshowCroppedImage(false);
    setclickImagePath(ImagePath);
    seteditImageObject(ImageObject);
    seteditImageIndex(index);
  };

  /**
   * This function will open image in edit mode
   */
  const editImage = () => {
    if (showCroppedImage === false) {
      setshowCroppedImage(true);
    } else {
      setshowCroppedImage(false);
    }
  };

  /**
   * This function will remove image from array
   * @param {string} ImagePath
   * @param {number} index
   */
  const removeImage = (ImagePath, index) => {
    allReadImageFilesObjects.splice(index, 1);
    allImageFiles.splice(index, 1);
    if (allReadImageFilesObjects.length === 0) {
      closeUploadImageModal();
      return;
    }
    if (index === 0 && ImagePath === clickImagePath) {
      setclickImagePath(allReadImageFilesObjects[index].path);
      setcaptionValue(allReadImageFilesObjects[index].caption);
      seteditImageIndex(index);
    } else if (
      index === allReadImageFilesObjects.length &&
      ImagePath === clickImagePath
    ) {
      setclickImagePath(allReadImageFilesObjects[index - 1].path);
      setcaptionValue(allReadImageFilesObjects[index - 1].caption);
      seteditImageIndex(index - 1);
    } else {
      if (index === editImageIndex) {
        seteditImageIndex(index);
        setclickImagePath(allReadImageFilesObjects[index].path);
        setcaptionValue(allReadImageFilesObjects[index].caption);
      } else if (index < editImageIndex) {
        seteditImageIndex((editImageIndex) => editImageIndex - 1);
      }
    }
    setrerender(!rerender);
  };

  /**
   * This funciton will rotate the image
   */
  const rotateImage = () => {
    if (cropper) {
      cropper.rotate(90);
    }
  };

  /**
   * This funciton will manage cropper
   * @param {object} instance
   */
  const manageCropper = (instance) => {
    setCropper(instance);
  };

  /**
   * This funciton will save edit image
   * @param {number} index
   * @param {string} image
   */
  const saveEditImage = (index, image) => {
    let imageObj = {};
    imageObj.path = cropper.getCroppedCanvas(image.mime_type).toDataURL();
    imageObj.mime_type = image.mime_type;
    imageObj.name = image.name;
    if (typeof cropper !== "undefined") {
      allReadImageFilesObjects.splice(index, 1, imageObj);
    }
    dataURLtoFile(
      cropper.getCroppedCanvas(image.mime_type).toDataURL(),
      index,
      image.name,
      imageObj
    );
  };

  /**
   * This function will convert dataURL to file
   * @param {string} dataurl
   * @param {number} index
   * @param {string} filename
   * @param {object} imageObj
   */
  const dataURLtoFile = (dataurl, index, filename, imageObj) => {
    let arr = dataurl.split(","),
      mime = arr[0].match(/:(.*?);/)[1],
      bstr = atob(arr[1]),
      n = bstr.length,
      u8arr = new Uint8Array(n);

    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }
    let croppedImage = new File([u8arr], filename, { type: mime });
    allImageFiles.splice(index, 1, croppedImage);
    showClickImage(imageObj.path, imageObj, index);
  };

  /**
   * This funciton will send image on server
   */
  const sendImage = () => {
    if (allImageFiles.length > 0) {
      sendImageOnServer(allImageFiles, allReadImageFilesObjects);
    }
  };

  const handleCaption = (e, readImageFilesObjects) => {
    setcaptionValue(e.target.value);
    readImageFilesObjects.caption = e.target.value;
  };

  return (
    <div className="upload_Image">
      <Modal
        isOpen={showUploadImageModal}
        size="lg"
        style={{ maxWidth: "700px", width: "100%" }}
      >
        <ModalHeader toggle={closeUploadImageModal}>
          <p>Upload Media</p>
        </ModalHeader>
        <ModalBody style={{background:"#f7f7f7"}}>
          {filesLoader || loading ? (
            <div className="custom-loader">
              <Spinner animation="grow" size="sm" />
            </div>
          ) : (
            <div>
              {
                <div className="img-action mt-1">
                  <span
                    className="custom-btn mr-1"
                    disabled={disablebtn}
                    onClick={() => editImage()}
                  >
                    <i className="fas fa-crop-alt"></i>
                  </span>
                </div>
              }
              {showCroppedImage ? (
                <div>
                  <Cropper
                    className="clickImage_container"
                    movable={true}
                    zoomable={true}
                    dragMode="move"
                    zoomOnTouch={true}
                    zoomOnWheel={true}
                    initialAspectRatio={1}
                    src={clickImagePath}
                    viewMode={2}
                    rotatable={true}
                    minCropBoxHeight={40}
                    minCropBoxWidth={40}
                    background={true}
                    responsive={true}
                    cropBoxMovable={false}
                    autoCrop={true}
                    autoCropArea={1}
                    modal={true}
                    checkOrientation={false} // https://github.com/fengyuanchen/cropperjs/issues/671
                    onInitialized={(instance) => manageCropper(instance)}
                    guides={true}
                    center={true}
                    highlight={false}
                    dragMode="move"
                  />
                  <div className="text-center">
                    <Button onClick={rotateImage} className="btn-sm custom-btn">
                      <i className="fas fa-sync"></i>
                    </Button>
                    <Button
                      onClick={() =>
                        saveEditImage(editImageIndex, editImageObject)
                      }
                      className="btn-sm custom-btn"
                    >
                      <i className="fas fa-check"></i>
                    </Button>
                  </div>
                </div>
              ) : (
                <div className="clickImage_container">
                  <img src={clickImagePath} alt="img" />
                </div>
              )}
              {allReadImageFilesObjects.map((readImageFilesObjects, index) => {
                return (
                  <div className="caption-container">
                    {index === editImageIndex && (
                      <TextareaAutosize
                        placeholder={`Add a caption`}
                         style={{padding:"5px 10px"}}
                        minRows={1.5}
                        value={captionValue}
                        onChange={(e) =>
                          handleCaption(e, readImageFilesObjects)
                        }
                      />
                    )}
                  </div>
                );
              })}

              <div className="files_container">
                {allReadImageFilesObjects.map((imageFileObject, index) => {
                  return (
                    <div key={index}>
                      <div
                        className="files mx-2 my-2 "
                        style={{
                          border:
                            imageFileObject.path === clickImagePath &&
                            editImageIndex === index
                              ? "3px solid #636ac6"
                              : "none",
                        }}
                      >
                        <span
                          onClick={() =>
                            showClickImage(
                              imageFileObject.path,
                              imageFileObject,
                              index
                            )
                          }
                        >
                          <img src={imageFileObject.path} alt="img" />
                        </span>
                        <span
                          onClick={() =>
                            removeImage(imageFileObject.path, index)
                          }
                        >
                          <i
                            className="fas fa-times"
                            type="button"
                            value=" Click "
                          />
                        </span>
                      </div>
                    </div>
                  );
                })}
                <div className="custom_input">
                  <label>
                    <input
                      type="file"
                      accept="image/png,image/jpg,image/jpeg"
                      onChange={onChangeImage}
                      multiple
                      disabled={disablebtn}
                    />
                    <i className="fas fa-plus" />
                  </label>
                </div>
              </div>
            </div>
          )}
           {disableButton&&
            <div className="progress_bar_container">
            <Line percent={percentCompleted} strokeWidth="1" strokeColor="#636ac6" />
            <p>Progress: {percentCompleted}%</p>
            </div>
            }
        </ModalBody>
        <ModalFooter>
          <Button
            onClick={sendImage}
            disabled={disableButton}
            className="custom-btn-fill"
            size="sm"
          >
            {disableButton ? "Uploading..." : "Upload"}
          </Button>
          <Button
            onClick={closeUploadImageModal}
            className="custom-btn"
            size="sm"
          >
            {" "}
            Close
          </Button>
        </ModalFooter>
      </Modal>
    </div>
  );
};

export default UploadImage;

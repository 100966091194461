import React, { useState,useEffect, useRef } from "react";
import {
  Button,
  Label,
  FormGroup,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from "reactstrap";
import Slider from "react-slick";

const PreviewListImage = (props) => {
  console.log("props",props);
  const { showListImageModal,itemMediaId,listAllImage,closeListImageModal} = props;

  const sliderRef = useRef();
  

  const [activeSlide, setActiveSlide] = useState(0);


  useEffect(() => {
    if(listAllImage.length>0){
      listAllImage.map((listimageObj,index)=>{
           if(listimageObj.item_media_id===itemMediaId){
            setActiveSlide(index);
           }
      })
    }

  }, []);

  useEffect(() => {
    if (sliderRef.current?.slickGoTo) {
      sliderRef.current.slickGoTo(activeSlide);
    }
  }, [activeSlide]);

  const settings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  };

  return (
    <div>
      <Modal isOpen={showListImageModal} size="lg" >
        <ModalHeader toggle={closeListImageModal}>
          <h5 className="modal-title f-w-600" id="exampleModalLabel2">
          Images
          </h5>
        </ModalHeader>
        <ModalBody>
          <FormGroup className="form-group mb-3 row">
            <Label className="col-xl-12 col-sm-12 mb-0">
            
            </Label>
            <Slider {...settings} {...settings} ref={sliderRef}>
            {
              listAllImage.map((imgObj)=>{
                return <img src={imgObj.file_path} alt="image"/>
               
              })
            }
             </Slider>
          </FormGroup>
        </ModalBody>
        <ModalFooter>
          
        </ModalFooter>
      </Modal>
    </div>
  );
};

export default PreviewListImage